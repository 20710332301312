import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import { Fetch, catchError } from "../../../utils/fetch";
import { loginAction, permissionsAction } from "../../../redux/actions";
import { AlertInfo, AlertSuccess, AlertError } from "../../../components/alert";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function getQueryParams(queryString) {
  const queryParams = new URLSearchParams(queryString);
  const params = {};

  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }

  return params;
}

function UpdatePassPage(props) {
  const history = useHistory();
  const { loginActionProps, permissionsActionProps } = props;
  const [passRepeat, setPassRepeat] = React.useState(null);
  const [pass, setPass] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState(true);

  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const [user, setUser] = React.useState(queryParams.email);

  const auth = async () => {
    try {
      const { data } = await axios({
        url: process.env.REACT_APP_BASE_URL + "/external-auth/auth",
        method: "POST",
      });
      return data;
    } catch (err) {}
  };

  const external = async (data, token) => {
    try {
      return await axios({
        url: `${process.env.REACT_APP_BASE_URL_GW_API}/customers/Api/v1/login`,
        data,
        method: "post",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
    } catch (err) {}
  };

  // const onLoginSql = async () => {
  //   // const { token } = await auth();
  //   // const { data } = await external({ email: user, password: pass }, token);

  //   // return {
  //   //   error: data?.error,
  //   //   tokenApp: token,
  //   //   tokenUser: data?.data?.Token,
  //   // };
  // };

  const onLoginSql = async () => {
    const { token } = await auth();
    const { data } = await external({ email: user, password: pass }, token);

    return {
      error: data?.error,
      tokenApp: token,
      tokenUser: data?.data?.Token,
    };
  };

  const checkPermissions = async (rol) => {
    try {
      setLoading(true);

      const { data } = await Fetch("users-permissions/roles/" + rol.id, "get");
      const permissions = data.role.permissions.application.controllers;

      permissionsActionProps(permissions);
      AlertSuccess("Login exitoso!");

      if (rol.type == "aprobador" || rol.type == "creador") {
        history.push("/dashboard");
      } else {
        history.push("/inicio");
      }
    } catch (error) {
      catchError(error);
      AlertInfo(
        "Se ha presentado un problema al intentar hacer login, verifique usuario y contraseña"
      );
      setLoading(false);
      console.error("mostrando error login", error);
    }
  };

  const iniciarSesion = async () => {
    localStorage.clear();
    const params = {
      identifier: user,
      password: pass,
    };
    try {
      setLoading(true);

      //LOGIN SQL
      const loginSql = await onLoginSql();

      if (loginSql.error) throw "Login no válido";

      //LOGIN STRAPI
      const { data } = await Fetch("auth/local", "post", params);

      localStorage.setItem("jwt_token", data.jwt);
      localStorage.setItem("tokenApp", loginSql.tokenApp);
      localStorage.setItem("tokenUser", loginSql.tokenUser);
      localStorage.setItem("userData", JSON.stringify(data.user));
      localStorage.setItem("empresa", JSON.stringify(data.user.empresa.nombre));

      loginActionProps(data.jwt, data.user);
      checkPermissions(data.user.role);

      setLoading(false);
    } catch (error) {
      catchError(error);
      AlertInfo("Credenciales incorrectas, favor verificar");
      setLoading(false);
      console.error("mostrando error login", error);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    Fetch(`customuser/update-password/external`, "post", {
      token: queryParams.token,
      email: queryParams.email,
      password: pass,
      typeEmail: "EMAIL_BODY_RECOVER",
      pshb: false,
    })
      .then((response) => {
        if (response.data.error) {
          AlertError(response.data.message);
        } else {
          AlertSuccess(response.data.message);
          //history.push("/usuarios/cms/lista");
          iniciarSesion();
        }
      })
      .catch((error) => {
        AlertError("Los datos no pudieron ser cambiados");
      })
      .finally((fin) => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="misc-wrapper">
        <div className="misc-content">
          <Container>
            <Row className="justify-content-center">
              <Col sm="12" md="5" lg="4" className="col-4">
                <div to="#javascript" className="misc-header text-center">
                  <Link to="/">
                    <Image
                      alt=""
                      src="/assets/img/logo-hb.png"
                      className="toggle-none hidden-xs"
                    />
                  </Link>
                </div>
                <div className="misc-box">
                  <Form>
                    <Form.Group>
                      <label htmlFor="exampleuser1">Contraseña nueva</label>
                      <div className="group-icon">
                        <input
                          id="exampleuser1"
                          type={type ? "password" : "text"}
                          onChange={(e) => setPass(e.target.value)}
                          placeholder=""
                          className="form-control"
                        />
                        <span className="icon-input">
                          {type ? (
                            <FontAwesomeIcon
                              icon={faEyeSlash}
                              onClick={() => setType(!type)}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faEye}
                              onClick={() => setType(!type)}
                            />
                          )}
                        </span>
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <label htmlFor="exampleInputPassword1">
                        Confirmar contraseña nueva
                      </label>
                      <div className="group-icon">
                        <input
                          id="exampleInputPassword1"
                          type={type ? "password" : "text"}
                          onChange={(e) => setPassRepeat(e.target.value)}
                          placeholder=""
                          className="form-control"
                        />
                        <span className="icon-input">
                          {type ? (
                            <FontAwesomeIcon
                              icon={faEyeSlash}
                              onClick={() => setType(!type)}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faEye}
                              onClick={() => setType(!type)}
                            />
                          )}
                        </span>
                      </div>
                      {passRepeat !== "" && pass !== passRepeat && (
                        <div className="text-danger">
                          Las contraseñas deben de ser iguales
                        </div>
                      )}
                    </Form.Group>
                    <Button
                      type="button"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={loading || pass !== passRepeat || pass === ""}
                      className="btn btn-block btn-primary btn-rounded box-shadow"
                    >
                      {loading ? "Cargando..." : "Iniciar"}
                    </Button>
                  </Form>
                </div>
                <div className="text-center misc-footer">
                  <p>Copyright © 2024 Hospital Británico</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginActionProps: (data, user) => {
      dispatch(loginAction(data, user));
    },
    permissionsActionProps: (data) => {
      dispatch(permissionsAction(data));
    },
  };
};

export default connect(null, mapDispatchToProps)(UpdatePassPage);
