/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import DataGridForm from "../../../../components/common/DataGridForm";
import { loadingAction } from "../../../../redux/actions";
import { catchError, Fetch, FetchApi } from "../../../../utils/fetch";
import { formatDate } from "../../../../utils/formatDate";
import "./correos-pacientes.scss";
import JoditEditor, { Jodit } from "jodit-react";
import { validateModel } from "./validationSchema";
import { AlertInfo } from "../../../../components/alert";
import { Formik } from "formik";
import { removeSpecial, removeSpecialJodit } from "../../../../utils/tools";
import { fetchLogs } from "../../../../utils/fetch";

function CorreosPacientes({ isLoadingAction, isLoading }) {
  const location = useLocation();
  const URL_SECCION = 2;
  const pathUrl = location.pathname.split("/")[URL_SECCION];
  const [contenido, setContenido] = React.useState(null);
  const [setting, setSetting] = React.useState(
    pathUrl === "correos-pacientes" ? "CONFIRM" : "CONFIRM_COMPANY"
  );
  const [listSetting, setListSetting] = React.useState([]);
  const user = useSelector((state) => state.user.data);

  const settingPatient = [
    { codigo: "CONFIRM", descripcion: "Registracion de nuevo usuario" },
    { codigo: "NEW_USERS", descripcion: "Validación de correo usuario" },
    { codigo: "BLOCKPASSWORD", descripcion: "Bloqueo de contraseña" },
    { codigo: "CHANGEPASSWORD", descripcion: "Pedido de cambio de clave" },
    { codigo: "RECOVER", descripcion: "Cambio de clave exitoso" },
    { codigo: "CHANGEEMAIL", descripcion: "Pedido de cambio de email" },
    { codigo: "CHANGEDEMAIL", descripcion: "Cambio de email exitoso" },
    {
      codigo: "ACCOUNT_ACTIVATED",
      descripcion: "Cuenta activada exitosamente",
    },
    {
      codigo: "ACCOUNT_DELETED",
      descripcion: "Cuenta desactivada exitosamente",
    },
    { codigo: "MONTHLY_INVOICES", descripcion: "Aviso de factura disponible" },
    // { codigo: "MEET", descripcion: "Nuevo turno" },
    // { codigo: "MODIFY", descripcion: "Modificación de turno" },
    // { codigo: "MEET_CANCEL", descripcion: "Cancelacion de Turno" },
    // { codigo: "MEET_REMINDER", descripcion: "Recordatorio del Turno" },
    {
      codigo: "UNLINK_FAMILY_MEMBER",
      descripcion: "Desvincular miembro familiar",
    },
  ];

  const settingCompany = [
    { codigo: "CONFIRM_COMPANY", descripcion: "Registracion de nuevo usuario" },
    { codigo: "NEW_COMPANY", descripcion: "Validación de correo usuario" },
    { codigo: "BLOCKPASSWORD_COMPANY", descripcion: "Bloqueo de contraseña" },
    {
      codigo: "CHANGEPASSWORD_COMPANY",
      descripcion: "Pedido de cambio de clave",
    },
    { codigo: "RECOVER_COMPANY", descripcion: "Cambio de clave exitoso" },
    {
      codigo: "MONTHLY_INVOICES_COMPANY",
      descripcion: "Aviso de factura disponible",
    },
  ];

  React.useEffect(() => {
    if (pathUrl === "correos-pacientes") setListSetting(settingPatient);
    else setListSetting(settingCompany);
  }, []);

  React.useEffect(() => {
    getContenido();
  }, [setting]);

  const getContenido = async () => {
    isLoadingAction(true);
    try {
      const { data } = await FetchApi(
        `patient/Api/v1/settings/GetMailEditor?setting=${setting}`,
        "get",
        null
      );
      setContenido(data?.data);
      isLoadingAction(false);
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  const onActualizar = async (values) => {
    isLoadingAction(true);

    values.note = removeSpecial(values.note);
    try {
      values.setting = setting;

      await FetchApi(
        `patient/Api/v1/settings/UpdateMailEditor`,
        "post",
        values
      );
      isLoadingAction(false);
      AlertInfo("Contenido actualizado");
      fetchLogs({
        idLogType: 2,
        idType: 12,
        idCustomer: user?.idCustomer ?? "",
        description: `El administrador ${user?.nombre} ${user?.apellido} (${user.email}) ha cambiado el mensaje del sistema: ${values?.setting} (${values?.title})."`,
      });
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  function preparePaste(jodit) {
    jodit.e.on(
      "paste",
      (e) => {
        jodit.e.stopPropagation("paste");
        let plainText = Jodit.modules.Helpers.getDataTransfer(e).getData(
          Jodit.constants.TEXT_PLAIN
        );
        // plainText = plainText.replace(/(^|;)\s*font-[^;]+/g, "");
        // let regex = /<(?!(\/\s*)?(div|b|i|em|strong|u|p)[>,\s])([^>])*>/g;
        // plainText = plainText.replace(regex, '');

        //plainText = plainText.normalize('NFKC').replaceAll("\n","<br/>").replaceAll("\r","");

        plainText = plainText.normalize("NFKC");
        plainText = plainText.replace(/\r\n/g, "\n");
        plainText = removeSpecialJodit(plainText);

        // Dividir el texto en líneas separadas
        const lines = plainText.split("\n");

        // Insertar cada línea como un nuevo nodo
        lines.forEach((line) => {
          if (line.startsWith("https://") || line.startsWith("http://")) {
            jodit.s.insertHTML(`<a href="${line}">${line}</a>`);
          } else {
            jodit.s.insertHTML(`<p>${line}</p>`);
          }
        });

        return false;
      },
      { top: true }
    );
  }
  Jodit.plugins.add("preparePaste", preparePaste);

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    height: 500,
    language: "es",
    disablePlugins: ["table"],
    disabled: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    defaultActionOnPaste: "insert_clear_html",
    enableDragAndDropFileToEditor: true,
    uploader: {
      url: process.env.REACT_APP_BASE_URL + "/upload",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt_token"),
      },
      data: null,
      filesVariableName: function (i) {
        return "files";
      },
      withCredentials: false,
      pathVariableName: "path",
      format: "json",
      method: "POST",
      prepareData: function (formData) {
        formData.append("id", 1);
      },
      isSuccess: function (resp) {
        return !resp.error;
      },
      getMessage: function (resp) {
        return resp.msgs.join("\n");
      },
      process: function (resp) {
        return resp;
      },
      defaultHandlerSuccess: function (resp) {
        for (var i = 0; i < resp.length; i++) {
          var img1 = new Image();
          img1.src = resp[i]["url"];
          img1.alt = "imagen";
          img1.className = "img-fluid";
          this.s.insertImage(img1);
          //this.s.insertImage(resp[i]['url'], { width: 'auto', height: 'auto' });
        }
      },
    },
  };

  const handleChangeJoiEditor = (setFieldValue, data) => {
    setFieldValue("note", data, false);
  };

  const onChange = (e) => {
    setSetting(e.target.value);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={contenido}
        enableReinitialize
        validationSchema={validateModel}
        onSubmit={(values, { setSubmitting }) => {
          onActualizar(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Header>
                    Modificación de mensajes para{" "}
                    {pathUrl === "correos-pacientes" ? "pacientes" : "empresas"}
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md="4">
                        <select
                          className="form-control"
                          onChange={onChange}
                          value={setting}
                        >
                          {listSetting?.map((item, indice) => (
                            <option key={indice} value={item.codigo}>
                              {item.descripcion}
                            </option>
                          ))}
                        </select>
                      </Col>

                      <Col md="4">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-custom input-group-text"
                              id="basic-addon1"
                            >
                              <i class="fas fa-at"></i>
                            </span>
                          </div>
                          <input
                            placeholder=""
                            type="text"
                            className="form-control"
                            name="emailAddress"
                            onChange={handleChange}
                            value={values?.emailAddress}
                          />
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              <i class="fas fa-refresh"></i>
                            </span>
                          </div>
                          <div className="text-danger">
                            {errors?.emailAddress}
                          </div>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-custom input-group-text"
                              id="basic-addon1"
                            >
                              <i class="fas fa-envelope"></i>
                            </span>
                          </div>
                          <input
                            placeholder=""
                            type="text"
                            className="form-control"
                            name="emailName"
                            onChange={handleChange}
                            value={values?.emailName}
                          />
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              <i class="fas fa-refresh"></i>
                            </span>
                          </div>
                          <div className="text-danger">{errors?.emailName}</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="mb-10">
                        <label>Tema del mensaje</label>
                        <input
                          type="text"
                          class="form-control"
                          name="title"
                          onChange={handleChange}
                          value={values?.title}
                        />
                        <div className="text-danger">{errors?.title}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <label>Cuerpo del mensaje</label>
                        <JoditEditor
                          config={config}
                          name="note"
                          value={values?.note}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={(newContent) =>
                            handleChangeJoiEditor(setFieldValue, newContent)
                          }
                        />
                        <div className="text-danger">{errors?.note}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <p style={{ fontWeight: "bold" }}>
                          VARIABLES DEL SISTEMA QUE SE PUEDEN UTILIZAR EN LOS
                          MENSAJES:
                        </p>

                        <span>
                          <b>%USER%</b> Nombre Completo del usuario
                        </span>
                        <br />
                        <span>
                          <b>%URL1%</b> URL1 del sitio del hospital britanico
                        </span>
                        <br />
                        <span>
                          <b>%URL2%</b> URL2 del sitio del hospital britanico
                        </span>
                        <br />
                        <span>
                          <b>%URL3%</b> URL3 del sitio del hospital britanico
                        </span>
                        <br />
                        <span>
                          <b>%EMAIL%</b> Email del usuario
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-15">
                      <Col md="2" className="mt-31">
                        <Button onClick={handleSubmit}>
                          <i className="fa fa-save mr-2"></i>Guardar
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CorreosPacientes);
