import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Col, Row, Button, Modal } from "react-bootstrap";
import { FetchApi } from "../../../../../utils/fetch";
import { AlertSuccess, AlertError } from "../../../../../components/alert";
import { validateModelDni, validateModelPasport } from "./validationSchema";
import { useSelector } from "react-redux";

const EditarIntegranteModal = ({
  isShow,
  member,
  handleClose,
  onDatosInciales,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [validationSchema, setValidationSchema] = useState({});
  const user = useSelector((state) => state.user.data);

  const handleSubmit = (values) => {
    setIsLoading(true);
    const data = {
      idCustomer: values.idCustomerPrincipal,
      familyMember: {
        id: values.id,
        familygroupId: values.idMiembro,
        relationshipid: values.relationshipId,
        firstName: values.nombres,
        lastName: values.apellidos,
        birthday: values.fechaNacimiento,
        dni: values.numeroDocumento,
        dniType: values.dniType,
        genderid: values.genderId,
        credential: values.credencial,
        fileDocLegal: "",
        statusCode: values.idEstado,
      },
    };
    FetchApi(`customers/api/v1/familiargroup/updatefamilymember`, "post", data)
      .then((res) => {
        const data = res?.response?.data;
        if (data?.Message?.Generic?.includes("DNI duplicado")) {
          AlertError("El documento ya se encuentra registrado.");
          return;
        }
        if (res?.code == "ERR_BAD_RESPONSE") {
          AlertError("No se pudo actualizar, intentelo de nuevo.");
          return;
        }
        AlertSuccess("Actualizado correctamente.");
        handleClose();
        onDatosInciales();
      })
      .catch(() => {
        AlertError("Error al actualizar.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (isShow) {
      if (!member?.isBornHB) {
        member.isBornHB = "No";
      }
      formik.setValues({ ...member });
    }
  }, [isShow]);

  useEffect(() => {
    if (formik.values?.dniType == "0") {
      setValidationSchema(validateModelDni);
    } else {
      setValidationSchema(validateModelPasport);
    }
  }, [formik.values?.dniType]);

  return (
    <Modal show={isShow} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>MODIFICACION DE INTEGRANTE GF</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md="12">
              <Row className="">
                <Col md="6">
                  <label>Nombre completo </label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-custom input-group-text"
                        id="basic-addon1"
                      >
                        <i class="fas fa-user"></i>
                      </span>
                    </div>
                    <input
                      disabled
                      placeholder=""
                      type="text"
                      name="nombreCompleto"
                      value={formik.values?.nombreCompleto}
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md="3">
                  <label>Documento</label>
                  <div className="input-group mb-3">
                    <select
                      name="dniType"
                      onChange={formik.handleChange}
                      value={formik.values?.dniType}
                      class="form-control"
                      defaultValue={formik.values?.dniType}
                      disabled={
                        user?.role?.type != "superadmin" ||
                        formik.values?.idEstado == "RCZ"
                      }
                    >
                      <option value="0">DNI</option>
                      <option value="2">Pasaporte</option>
                      <option value="1" disabled>
                        Cédula
                      </option>
                      <option value="3" disabled>
                        Libreta Cívica
                      </option>
                    </select>
                  </div>
                  <div className="text-danger">
                    {formik.errors?.tipoDocumento}
                  </div>
                </Col>

                <Col md="3" className="mt-31">
                  <input
                    type="text"
                    name="numeroDocumento"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values?.numeroDocumento}
                    className="form-control"
                    disabled={
                      user?.role?.type != "superadmin" ||
                      formik.values?.idEstado == "RCZ"
                    }
                  />
                  <div className="text-danger">
                    {formik.errors?.numeroDocumento}
                  </div>
                </Col>

                <Col md="3">
                  <label>Credencial</label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      placeholder=""
                      type="text"
                      name="credencial"
                      value={formik.values?.credencial}
                      className="form-control"
                    />
                  </div>
                </Col>

                <Col md="3">
                  <label>Fecha de nacimiento</label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      placeholder="dd-mm-yyyy"
                      type="text"
                      name="fechaNacimiento"
                      value={formik.values?.fechaNacimiento}
                      className="form-control"
                    />
                  </div>
                </Col>

                <Col md="3">
                  <label>Parentesco </label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      placeholder=""
                      type="text"
                      name="parentesco"
                      value={formik.values?.parentesco}
                      className="form-control"
                    />
                  </div>
                </Col>

                <Col md="3">
                  <label>Fecha de solicitud</label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      placeholder="dd-mm-yyyy"
                      type="text"
                      name="Member.birthday"
                      value={formik.values?.fechaSolicitud}
                      className="form-control"
                    />
                  </div>
                </Col>

                <Col md="3">
                  {formik.values?.isBornHB}
                  <label>Nac/Atd HB</label>
                  <div className="input-group mb-3">
                    <select
                      name="isBornHB"
                      onChange={formik.handleChange}
                      value={formik.values?.isBornHB}
                      class="form-control"
                      defaultValue={formik.values?.isBornHB}
                      disabled
                    >
                      <option value="Si">Si</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  <div className="text-danger">
                    {formik.errors?.tipoDocumento}
                  </div>
                </Col>

                <Col md="3">
                  <label>Estado</label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      placeholder=""
                      type="text"
                      name="estado"
                      value={formik.values?.estado}
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md="3">
                  <label>Observación</label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      placeholder=""
                      type="text"
                      name="observacion"
                      value={formik.values?.observacion}
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md="3">
                  <label>Responsable</label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      placeholder=""
                      type="text"
                      name="Member.firstName"
                      value={formik.values?.responsable}
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md="3">
                  <label>IDTEMP</label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      placeholder=""
                      type="text"
                      name="Member.firstName"
                      value={formik.values?.idTemp}
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md="3">
                  <label>IDSAP</label>
                  <div className="input-group mb-3">
                    <input
                      disabled
                      placeholder=""
                      type="text"
                      name="Member.firstName"
                      value={formik.values?.idSap}
                      className="form-control"
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="2" className="mt-31">
                  <Button
                    type="button"
                    onClick={() => formik.handleSubmit()}
                    disabled={isLoading}
                  >
                    <i className="fa fa-save mr-2"></i>Guardar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditarIntegranteModal;
