import React from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import { Fetch, catchError, fetchLogs } from "../../../utils/fetch";
import { loginAction, permissionsAction } from "../../../redux/actions";
import { AlertInfo, AlertSuccess } from "../../../components/alert";

function LoginPage(props) {
  const history = useHistory();
  const { loginActionProps, permissionsActionProps } = props;
  const [user, setUser] = React.useState(null);
  const [pass, setPass] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState(true);

  const auth = async () => {
    try {
      const { data } = await axios({
        url: process.env.REACT_APP_BASE_URL + "/external-auth/auth",
        method: "POST",
      });
      return data;
    } catch (err) { }
  };

  const external = async (data, token) => {
    try {
      return await axios({
        url: `${process.env.REACT_APP_BASE_URL_GW_API}/customers/Api/v1/login`,
        data,
        method: "post",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
    } catch (err) { }
  };

  const onLoginSql = async () => {
    const { token } = await auth();
    const { data } = await external({ email: user, password: pass }, token);

    return {
      errorType: data?.data?.type ?? "UserValidated",
      error: data?.error,
      tokenApp: token,
      tokenUser: data?.data?.Token,
      message: data.message,
    };
  };

  const handleSubmit = async () => {
    localStorage.clear();
    const params = {
      identifier: user,
      password: pass,
    };
    try {
      setLoading(true);

      //LOGIN SQL
      const loginSql = await onLoginSql();

      if (loginSql.errorType) {
        switch (loginSql.errorType) {
          case "UserNotValidated":
            fetchLogs({
              idLogType: 2,
              idType: 15,
              description:
                "El usuario " + user + " intentó acceder, pero no está validado.",
            });
            break;
          case "AccountDeleted":
            fetchLogs({
              idLogType: 2,
              idType: 15,
              description:
                "El usuario " + user + " intentó acceder, pero está dado de baja.",
            });
            break;
          case "UserBlocked":
            fetchLogs({
              idLogType: 2,
              idType: 15,
              description:
                "El usuario " + user + " intentó acceder, pero su usuario se encuentra bloqueado por intentar ingresar en varias ocasiones.",
            });
            break;
          case "UserLoginError":
            fetchLogs({
              idLogType: 2,
              idType: 15,
              description:
                "El usuario " + user + " intentó acceder, pero su contraseña es incorrecta.",
            });
            break;
          case "UserNoExist":
            fetchLogs({
              idLogType: 2,
              idType: 15,
              description:
                "El usuario " + user + " intentó acceder, pero no existe en la base.",
            });
            break;
          default:
            fetchLogs({
              idLogType: 2,
              idType: 14,
              description:
                "El usuario " + user + " se ha autenticado exitosamente contra la base del Portal.",
            });
            break;
        }
      }
      
      if (loginSql.error) {
        AlertInfo(loginSql.message === "Contraseña incorrecta" ? "Usuario o Contraseña Incorrecta" : loginSql.message);
        setLoading(false);
        return;
      }

      //LOGIN STRAPI
      let data;
      try {
        data = await Fetch("auth/local", "post", params);
        data = data.data;
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.data) {
          const msg = JSON.stringify(error?.response?.data?.data);
          if (msg.includes("Your account has been blocked")) {
            AlertInfo("Su usuario fue bloqueado, por favor comuníquese con su superior");
          } else {
            AlertInfo("Error al iniciar sesión intente de nuevo o contacte al administrador");
          }
        } else {
          AlertInfo("Error al iniciar sesión intente de nuevo o contacte al administrador");
        }
        return
      }

      localStorage.setItem("jwt_token", data.jwt);
      localStorage.setItem("tokenApp", loginSql.tokenApp);
      localStorage.setItem("tokenUser", loginSql.tokenUser);
      localStorage.setItem("userData", JSON.stringify(data.user));
      localStorage.setItem("empresa", JSON.stringify(data.user.empresa.nombre));

      loginActionProps(data.jwt, data.user);
      checkPermissions(data.user.role);

      setLoading(false);
      fetchLogs({
        idLogType: 2,
        idType: 14,
        description:
          "El usuario " + user + " se ha autenticado exitosamente contra la base de Strapi.",
      });
    } catch (error) {
      fetchLogs({
        idLogType: 2,
        idType: 15,
        description:
          "El usuario " + user + " no ha podido autenticarse contra la base de Strapi, presenta el siguiente error: " + error?.response ?? ".."+".",
      });
      catchError(error);
      AlertInfo("Credenciales incorrectas, favor verificar");
      setLoading(false);
      console.error("mostrando error login", error);
    }
  };

  const checkPermissions = async (rol) => {
    try {
      setLoading(true);

      const { data } = await Fetch("users-permissions/roles/" + rol.id, "get");
      const permissions = data.role.permissions.application.controllers;

      permissionsActionProps(permissions);
      AlertSuccess("Login exitoso!");

      if (rol.type == "aprobador" || rol.type == "creador") {
        history.push("/dashboard");
      } else {
        history.push("/inicio");
      }
    } catch (error) {
      catchError(error);
      AlertInfo(
        "Se ha presentado un problema al intentar hacer login, verifique usuario y contraseña"
      );
      setLoading(false);
      console.error("mostrando error login", error);
    }
  };
  return (
    <React.Fragment>
      <div className="misc-wrapper">
        <div className="misc-content">
          <Container>
            <Row className="justify-content-center">
              <Col sm="12" md="5" lg="4" className="col-4">
                <div to="#javascript" className="misc-header text-center">
                  <Link to="/">
                    <Image
                      alt=""
                      src="/assets/img/logo-hb.png"
                      className="toggle-none hidden-xs"
                    />
                  </Link>
                </div>
                <div className="misc-box">
                  <Form>
                    <Form.Group>
                      <label htmlFor="exampleuser1">Usuario</label>
                      <div className="group-icon">
                        <input
                          id="exampleuser1"
                          type="text"
                          onChange={(e) => setUser(e.target.value)}
                          placeholder="Usuario"
                          className="form-control"
                        />
                        <span className="icon-user text-muted icon-input" />
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <label htmlFor="exampleInputPassword1">Contraseña</label>
                      <div className="group-icon">
                        <input
                          id="exampleInputPassword1"
                          type={type ? "password" : "text"}
                          onChange={(e) => setPass(e.target.value)}
                          placeholder="Password"
                          className="form-control"
                        />
                        <span
                          className={
                            (type ? "icon-lock" : "icon-lock-open") +
                            " text-muted icon-input"
                          }
                          style={{ cursor: "pointer" }}
                          onClick={() => setType(!type)}
                        />
                      </div>
                    </Form.Group>
                    <Button
                      type="button"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={loading}
                      className="btn btn-block btn-primary btn-rounded box-shadow"
                    >
                      {loading ? "Cargando..." : "Iniciar"}
                    </Button>
                  </Form>
                </div>
                <div className="text-center misc-footer">
                  <p>Copyright © 2024 Hospital Británico</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginActionProps: (data, user) => {
      dispatch(loginAction(data, user));
    },
    permissionsActionProps: (data) => {
      dispatch(permissionsAction(data));
    },
  };
};

export default connect(null, mapDispatchToProps)(LoginPage);
