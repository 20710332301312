import React from "react";
import { FieldArray, Field } from "formik";
import { Row, Col, Form, Button } from "react-bootstrap";

const ObservacionesComponent = ({
  values,
  handleChange,
  setFieldValue,
  onEnviarComentario,
  getUserInitials,
  getUserName,
  formatDate,
}) => {
  return (
    <>
      <Row>
        <Col>
          <Form.Group>
            <label>Agregar Observación</label>
            <textarea
              name="observacionEnviar"
              onChange={handleChange}
              className="form-control"
              value={values.observacionEnviar}
            >
              {values.observacionEnviar}
            </textarea>
          </Form.Group>
        </Col>
      </Row>
      <div
        className="row mb-3"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "20px",
        }}
      >
        <Button
          type="button"
          onClick={() => setFieldValue("observacionEnviar", "")}
          className="cancel-button"
          style={{ marginRight: "10px", backgroundColor: "#9d9d9d !important" }}
        >
          Cancelar
        </Button>
        <Button
          type="button"
          onClick={() => onEnviarComentario(values, setFieldValue)}
          className="btn btn-info no-hover2"
        >
          Enviar
        </Button>
      </div>
      <FieldArray
        name="observacionesRepetible"
        render={(arrayHelpers) => (
          <div>
            {values.observacionesRepetible &&
            values.observacionesRepetible.length > 0 ? (
              values.observacionesRepetible.map((observacion, index) => (
                <div key={index}>
                  <Row>
                    <Col className="col-1 pr-0">
                      <div
                        className="rounded-circle text-white d-flex align-items-center justify-content-center"
                        style={{
                          width: "40px",
                          height: "40px",
                          backgroundColor: "#36a3f7",
                        }}
                      >
                        {getUserInitials(observacion.publicador)}
                      </div>
                    </Col>
                    <Col className="col-11 pl-0">
                      <Form.Group>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#e9ecef",
                          }}
                        >
                          <div
                            style={{
                              marginLeft: "10px",
                              marginRight: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {getUserName(observacion.publicador)}
                          </div>
                          -
                          <div style={{ marginLeft: "10px" }}>
                            {formatDate(observacion.fechaObservacion)}
                          </div>
                        </div>
                        <Field
                          name={`observacionesRepetible.${index}.observacion`}
                          as="textarea"
                          className="form-control"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              ))
            ) : (
              <div style={{ marginBottom: "16px" }}>No hay observaciones.</div>
            )}
          </div>
        )}
      />
    </>
  );
};

export default ObservacionesComponent;
