/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Card,
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import { connect } from "react-redux";
import { AlertInfo } from "../../../components/alert";
import DataGridForm from "../../../components/common/DataGridForm";
import { loadingAction } from "../../../redux/actions";
import { catchError, Fetch, FetchApi } from "../../../utils/fetch";
import "./informes-facturas.scss";

function InformesFacturas({ isLoadingAction, isLoading }) {
  const [contenido, setContenido] = React.useState(null);
  const [buscar, setBuscar] = React.useState("");
  const [visibleModal, setVisibleModal] = React.useState(false);
  const [visibleEstudioModal, setVisibleEstudioModal] = React.useState(false);
  const [facturas, setFacturas] = React.useState([]);
  const [estudios, setEstudios] = React.useState([]);
  const [anio, setAnio] = React.useState({
    anioDefault: new Date().getFullYear(),
    anios: [],
    dni: "",
    idSap: "",
    idCustomer: "",
  });

  React.useEffect(() => {
    getContenido();
    onAnios();
  }, []);

  React.useEffect(() => {
    onConsultarEstudios(
      anio.dni,
      anio.idSap,
      anio.anioDefault.toString(),
      anio.idCustomer,
      anio.email
    );
  }, [anio.anioDefault]);

  const onAnios = async () => {
    let anioActual = new Date().getFullYear();
    let anios = [];
    while (anioActual >= 2017) {
      anios.push(anioActual);
      anioActual--;
    }
    setAnio({ ...anio, anios });
  };

  const onBuscar = async () => {
    await getContenido();
  };

  const getContenido = async () => {
    isLoadingAction(true);
    try {
      const { data } = await FetchApi(
        `patient/Api/v1/Costumers/GetCustomersByMailOrNameOrDni?searchValue=${buscar}`,
        "get",
        null
      );

      setContenido(data?.data?.customer);
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error en pacientes", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  const onVerFacturas = async (item) => {
    if (
      item.socialSecurityNumber === null ||
      item.socialSecurityNumber === undefined ||
      item.socialSecurityNumber === ""
    ) {
      AlertInfo("El usuario no tiene un número de seguro social");
      return false;
    }

    try {
      isLoadingAction(true);

      const { data } = await FetchApi(
        `patient/Api/v1/Costumers/GetInvoiceBySocialSecurity?socialSecurityNumber=${item.socialSecurityNumber}`,
        "get",
        null
      );

      setFacturas(data?.data?.socialSecurity);
      isLoadingAction(false);
      setVisibleModal(true);
    } catch (error) {
      console.error("mostrando error en pacientes", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  const onVerEstudios = async (item) => {
    if (item.idSap === null || item.idSap === undefined || item.idSap === "") {
      AlertInfo("El usuario no tiene un SapID");
      return false;
    }

    setAnio({
      ...anio,
      dni: item.dni,
      idSap: item.idSap,
      idCustomer: item.idCustomer,
      email: item.email
    });

    await onConsultarEstudios(
      item.dni,
      item.idSap,
      anio.anioDefault.toString(),
      item.idCustomer,
      item.email
    );

    setVisibleEstudioModal(true);
  };

  const onConsultarEstudios = async (dni, idSap, anio, idCustomer = "", email) => {
    try {
      isLoadingAction(true);

      const { data } = await FetchApi(
        `patient/Api/v1/Results/laboratoryresultsall`,
        "post",
        {
          dni: dni,
          idSap: idSap,
          year: anio,
          limit: 10,
          offset: 0,
          idCustomer: idCustomer,
          service: "all",
          search: "",
          sinLimite: true,
          email
        }
      );

      setEstudios(data?.data?.Results);
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error en pacientes", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  function Facturas(data) {
    return (
      <>
        <div role="button" onClick={() => onVerFacturas(data)}>
          <i className="fas fa-file"></i>
        </div>
      </>
    );
  }

  function Estudios(data) {
    return (
      <>
        <div role="button" onClick={() => onVerEstudios(data)}>
          <i className="fas fa-file"></i>
        </div>
      </>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>Ayuda al usuario</Card.Header>
            <Card.Body>
              <Row className="mb-15">
                <Col md="6">
                  <label>Usuario</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-custom input-group-text"
                        id="basic-addon1"
                      >
                        <i class="fas fa-envelope"></i>
                      </span>
                    </div>
                    <input
                      placeholder=""
                      type="text"
                      name="buscar"
                      onChange={(e) => setBuscar(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md="2" className="mt-31">
                  <Button onClick={onBuscar}>
                    <i className="fa fa-search mr-2"></i>Buscar
                  </Button>
                </Col>
              </Row>

              <div className="table-responsive">
                <DataGridForm
                  dataSource={contenido}
                  keyExpr="idCustomer"
                  columns={[
                    { caption: "Nombre", dataField: "firstName" },
                    { caption: "Apellido", dataField: "lastName" },
                    { caption: "Email", dataField: "email" },
                    { caption: "Fecha de nacimiento", dataField: "birthday" },
                    { caption: "DNI", dataField: "dni" },
                    { caption: "Género", dataField: "sex" },
                    {
                      caption: "Facturas",
                      alignment: "center",
                      render: "Facturas",
                      cellRender: { Facturas },
                    },
                    {
                      caption: "Estudios",
                      alignment: "center",
                      render: "Estudios",
                      cellRender: { Estudios },
                    },
                  ]}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        show={visibleModal}
        size="lg"
        onHide={() => setVisibleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Facturas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <DataGridForm
              dataSource={facturas}
              keyExpr="idInvoice"
              columns={[
                { caption: "Fecha", dataField: "date" },
                { caption: "Nombre", dataField: "name" },
                {
                  caption: "Tipo documento",
                  dataField: "invoiceTypeDescription",
                },
              ]}
            />
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={visibleEstudioModal}
        size="lg"
        onHide={() => setVisibleEstudioModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Estudios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <select
              className="form-control"
              onChange={(v) =>
                setAnio({ ...anio, anioDefault: v.target.value })
              }
            >
              {anio.anios.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </div>
          <br />
          {isLoading ? (
            "Cargando, favor espere"
          ) : (
            <div className="table-responsive">
              <DataGridForm
                dataSource={estudios}
                keyExpr="idLaboratory"
                columns={[
                  { caption: "Fecha", dataField: "dateDisplay" },
                  { caption: "Estudio", dataField: "description" },
                  { caption: "Tipo estudio", dataField: "service" },
                ]}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InformesFacturas);
