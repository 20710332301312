/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik';
import React from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AlertInfo } from '../../../../components/alert';
import DataGridForm from '../../../../components/common/DataGridForm';
import { loadingAction } from '../../../../redux/actions';
import { catchError, Fetch, FetchApi, fetchLogs } from '../../../../utils/fetch';
import { formatDate } from '../../../../utils/formatDate';
import "./seteo.scss";
import { validateModel } from './validationSchema';

function Seteo({ isLoadingAction, isLoading }) {
    const [contenido, setContenido] = React.useState(null)
    const user = useSelector((state) => state.user.data);

    React.useEffect(() => {
        getContenido()
    }, [])

    const getContenido = async () => {
        isLoadingAction(true)
        try {
            const { data } = await FetchApi(`patient/Api/v1/settings/GetGeneralSetup`, 'get', null)
            setContenido(data?.data)
            isLoadingAction(false)            
        } catch (error) {            
            isLoadingAction(false)
            catchError(error);
        }
    }
    
    const onActualizar = async (values) => {
        isLoadingAction(true)
        try {
            await FetchApi(`patient/Api/v1/settings/UpdateGeneralSetup`, 'post', values)            
            isLoadingAction(false)
            fetchLogs({
                idLogType: 2,
                idType: 10,
                description:`El administrador ${user.nombre} (${user.email}) ha cambiado los datos de configuración general.`,
              });
            AlertInfo('Contenido actualizado')
        } catch (error) {            
            isLoadingAction(false)
            catchError(error);
        }
    }
    
    const updateCacheSap = async () => {
        isLoadingAction(true)
        try {
            await FetchApi(`customers/api/v1/user/updatecache`, 'get')            
            isLoadingAction(false)
            fetchLogs({
                idCustomer: user?.idCustomer ?? "",
                idLogType: 2,
                idType: 10,
                description:`El administrador ${user.nombre} ${user.apellido} (${user.email})  ha actualizado la información de aseguradoras, planes, doctores y medicamentos.`,
              });
            AlertInfo('Contenido actualizado')
        } catch (error) {            
            isLoadingAction(false)
            catchError(error);
        }
    }

    return (
        <React.Fragment>

            <Formik
                initialValues={contenido}
                enableReinitialize
                validationSchema={validateModel}
                onSubmit={(values, { setSubmitting }) => {
                    onActualizar(values);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    /* and other goodies */
                }) => (


                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col md="12">
                                <Card>
                                    <Card.Header>
                                        Configuración general
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className='mb-15'>
                                            <Col md="3">
                                                <label>Timeout Pin (Minutos)</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-custom input-group-text" id="basic-addon1">
                                                            <i class="fas fa-clock"></i>
                                                        </span>
                                                    </div>
                                                    <input placeholder='Minutos' type="number" className="form-control" name="pin" onChange={handleChange} value={values?.pin} />
                                                </div>
                                                <div className="text-danger">{errors?.pin}</div>
                                            </Col>

                                            <Col md="4">
                                                <label>Url Sitio Institucional</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-custom input-group-text" id="basic-addon1">
                                                            <i class="fas fa-globe"></i>
                                                        </span>
                                                    </div>
                                                    <input placeholder='Url' type="text" className="form-control" name="url" onChange={handleChange} value={values?.url} />                                                    
                                                </div>
                                                <div className="text-danger">{errors?.url}</div>
                                            </Col>

                                            <Col md="2" className="mt-31">
                                                <Button onClick={handleSubmit}><i className="fa fa-save mr-2"></i>Guardar</Button>
                                            </Col>
                                            <Col md="2" className="mt-31">
                                                <Button type='button' onClick={updateCacheSap}><i className="fa fa-refresh mr-2"></i>Actualizar Caché Sap</Button>
                                            </Col>
                                        </Row>


                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </form>
                )}
            </Formik>


        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Seteo);