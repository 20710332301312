import { useEffect, useState } from "react";

import MenuAdminHB from "../data/menu-hb/menu-admin.json";
import MenuSuperAdminHB from "../data/menu-hb/menu-super-admin.json";
import MenuCreadorHB from "../data/menu-hb/menu-creador.json";
import MenuAprobadorHB from "../data/menu-hb/menu-aprobador.json";
import MenuTeleperformanceHB from "../data/menu-hb/menu-teleperformance.json";

import MenuCreadorPS from "../data/menu-ps/menu-creador.json";
import MenuAprobadorPS from "../data/menu-ps/menu-aprobador.json";
import MenuTeleperformancePS from "../data/menu-ps/menu-teleperformance.json";

const useMenu = (user) => {
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (!user) return;

    if (user.empresa.nombre == "HB") {
      switch (user.role.type) {
        case "creador":
          setMenu(MenuCreadorHB.filter((x) => x.isRender));
          break;
        case "aprobador":
          setMenu(MenuAprobadorHB.filter((x) => x.isRender));
          break;
        case "teleperformance":
          setMenu(MenuTeleperformanceHB.filter((x) => x.isRender));
          break;
        case "admin":
          setMenu(MenuAdminHB.filter((x) => x.isRender));
          break;
        case "superadmin":
          setMenu(MenuSuperAdminHB.filter((x) => x.isRender));
          break;
      }
    } else {
      switch (user.role.type) {
        case "creador":
          setMenu(MenuCreadorPS);
          break;
        case "aprobador":
          setMenu(MenuAprobadorPS);
          break;
        case "teleperformance":
          setMenu(MenuTeleperformancePS);
          break;
      }
    }
  }, [user]);

  return {
    menu,
  };
};

export default useMenu;
