/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik';
import React from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AlertInfo } from '../../../../components/alert';
import DataGridForm from '../../../../components/common/DataGridForm';
import { loadingAction } from '../../../../redux/actions';
import { catchError, Fetch, FetchApi, fetchLogs } from '../../../../utils/fetch';
import { formatDate } from '../../../../utils/formatDate';
import "./servidor-correo.scss";
import { validateModel } from './validationSchema';

function ServidorCorreo({ isLoadingAction, isLoading }) {
    const [contenido, setContenido] = React.useState(null)
    const user = useSelector((state) => state.user.data);


    React.useEffect(() => {
        getContenido()
    }, [])

    const getContenido = async () => {
        isLoadingAction(true)
        try {
            const { data } = await FetchApi(`patient/Api/v1/settings/GetMailSetup`, 'get', null)
            setContenido(data?.data)
            isLoadingAction(false)
        } catch (error) {
            isLoadingAction(false)
            catchError(error);
        }
    }

    const onActualizar = async (values) => {
        isLoadingAction(true)
        try {
            await FetchApi(`patient/Api/v1/settings/UpdateMailSetup`, 'post', values)
            isLoadingAction(false)
            fetchLogs({
                idCustomer: user?.idCustomer ?? "",
                idLogType: 2,
                idType: 10,
                description:`El administrador ${user.nombre} ${user.apellido} (${user.email}) ha cambiado los datos del servidor de correo.`,
              });
            AlertInfo('Contenido actualizado')
        } catch (error) {
            isLoadingAction(false)
            catchError(error);
        }
    }

    return (
        <React.Fragment>

            <Formik
                initialValues={contenido}
                enableReinitialize
                validationSchema={validateModel}
                onSubmit={(values, { setSubmitting }) => {
                    onActualizar(values);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    /* and other goodies */
                }) => (


                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col md="12">
                                <Card>
                                    <Card.Header>
                                        Modificación de servidor de correo electrónico
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className=''>
                                            <Col md="6">
                                                <label>Descripcion del email </label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-custom input-group-text" id="basic-addon1">
                                                            <i class="fas fa-envelope"></i>
                                                        </span>
                                                    </div>
                                                    <input placeholder='' type="text" className="form-control" name="emailDisplay" onChange={handleChange} value={values?.emailDisplay} />                                                    
                                                </div>
                                                <div className="text-danger">{errors?.emailDisplay}</div>
                                            </Col>

                                            <Col md="6">
                                                <label>Email </label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-custom input-group-text" id="basic-addon1">
                                                            <i class="fas fa-at"></i>
                                                        </span>
                                                    </div>
                                                    <input placeholder='' type="text" className="form-control" name="emailInfo" onChange={handleChange} value={values?.emailInfo} />                                                    
                                                </div>
                                                <div className="text-danger">{errors?.emailInfo}</div>
                                            </Col>
                                        </Row>

                                        <Row className=''>
                                            <Col md="6">
                                                <label>Casilla donde recibir errores de validación </label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-custom input-group-text" id="basic-addon1">
                                                            <i class="fas fa-bell-exclamation"></i>
                                                        </span>
                                                    </div>
                                                    <input placeholder='' type="text" className="form-control" name="emailValidation" onChange={handleChange} value={values?.emailValidation} />                                                    
                                                </div>
                                                <div className="text-danger">{errors?.emailValidation}</div>
                                            </Col>

                                            <Col md="6">
                                                <label>Casilla donde recibir los contactos  </label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-custom input-group-text" id="basic-addon1">
                                                            <i class="fas fa-address-book"></i>
                                                        </span>
                                                    </div>
                                                    <input placeholder='' type="text" className="form-control" name="emailContact" onChange={handleChange} value={values?.emailContact}/>                                                    
                                                </div>
                                                <div className="text-danger">{errors?.emailContact}</div>
                                            </Col>
                                        </Row>

                                        <Row className=''>
                                            <Col md="3">
                                                <label>Tiempo de espera máximo </label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-custom input-group-text" id="basic-addon1">
                                                            <i class="fas fa-hourglass"></i>
                                                        </span>
                                                    </div>
                                                    <input placeholder='' type="number" className="form-control" name="timeout" onChange={handleChange} value={values?.timeout} />
                                                </div>
                                                <div className="text-danger">{errors?.timeout}</div>
                                            </Col>

                                            <Col md="3">
                                                <label>Horas para el recordatorio del turno</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-custom input-group-text" id="basic-addon1">
                                                            <i class="fas fa-clock"></i>
                                                        </span>
                                                    </div>
                                                    <input placeholder='' type="number" className="form-control" name="reminder" onChange={handleChange} value={values?.reminder}/>
                                                </div>
                                                <div className="text-danger">{errors?.reminder}</div>
                                            </Col>

                                            <Col md="3">
                                                <label>Reintentos de envío</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-custom input-group-text" id="basic-addon1">
                                                            <i class="fas fa-repeat"></i>
                                                        </span>
                                                    </div>
                                                    <input placeholder='' type="number" className="form-control" name="retryAttempts" onChange={handleChange} value={values?.retryAttempts} />
                                                </div>
                                                <div className="text-danger">{errors?.retryAttempts}</div>
                                            </Col>

                                            <Col md="3">
                                                <label>Demora entre cada reintento</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-custom input-group-text" id="basic-addon1">
                                                            <i class="fas fa-repeat-1"></i>
                                                        </span>
                                                    </div>
                                                    <input placeholder='' type="number" className="form-control" name="retryDelay" onChange={handleChange} value={values?.retryDelay} />
                                                </div>
                                                <div className="text-danger">{errors?.retryDelay}</div>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md="2" className="mt-31">
                                                <Button onClick={handleSubmit}><i className="fa fa-save mr-2"></i>Guardar</Button>
                                            </Col>
                                        </Row>


                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </form>
                )}
            </Formik>


        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ServidorCorreo);