/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DataGridForm from "../../../../components/common/DataGridForm";
import { loadingAction } from "../../../../redux/actions";
import { catchError, Fetch, FetchApi } from "../../../../utils/fetch";
import { formatDate } from "../../../../utils/formatDate";
import "./logs-debito.scss";
import TiposLog from "../../../../data/tipo-log.json";
import { Formik, Field } from "formik";
// import { validateModel } from './validationSchema';
import CustomStore from "devextreme/data/custom_store";
import MonthPickerInput from "react-month-picker-input";
require("react-month-picker-input/dist/react-month-picker-input.css");

function LogsDebito({ isLoadingAction, isLoading }) {
  const [state, setState] = React.useState({
    tipoDebito: "",
  });
  const [contenido, setContenido] = React.useState(null);

  const getContenido = async (values) => {
    setContenido(
      new CustomStore({
        key: "guid",
        load() {
          isLoadingAction(true);
          const resultado = FetchApi(
            `psafiliates/api/v1/consultarlogdebito?limit=100000&tipoDebito=${values.tipoDebito}`,
            "get"
          )
            .then((response) => ({
              data: response?.data?.data?.ds,
              totalCount: response?.data?.data?.ds.length,
            }))
            .finally(() => {
              isLoadingAction(false);
            });

          return resultado;
        },
      })
    );
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={state}
        enableReinitialize
        // validationSchema={validateModel}
        onSubmit={(values, { setSubmitting }) => {
          getContenido(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Header>Logs débito</Card.Header>
                  <Card.Body>
                    <Row className="mb-15">
                      {/* <Col md="2">
                        <label>Mes</label>
                        <MonthPickerInput
                          // lang="es"
                          year={new Date().getFullYear()}
                          month={new Date().getMonth()}
                          onChange={function (
                            maskedValue,
                            selectedYear,
                            selectedMonth
                          ) {
                            setState({
                              ...state,
                              monthYear:
                                "01/" +
                                (selectedMonth + 1)
                                  .toString()
                                  .padStart(2, "00") +
                                "/" +
                                selectedYear,
                            });
                          }}
                        />
                      </Col> */}
                      {/* <Col md="2">
                        <label>Tipo log.</label>
                        <select
                          class="form-control"
                          name="idType"
                          onChange={handleChange}
                          value={values?.idType}
                        >
                          <option selected="selected" value="">
                            Seleccione uno
                          </option>
                          <option selected="selected" value="0">
                            Todos
                          </option>
                          {TiposLog.map((item, index) => {
                            return (
                              <option value={item.value}>{item.text}</option>
                            );
                          })}
                        </select>
                        <div className="text-danger">{errors?.idType}</div>
                      </Col> */}
                      <Col md="2">
                        {/* <label>Descripción</label>
                        <input
                          type="text"
                          class="form-control"
                          name="description"
                          onChange={handleChange}
                          value={values?.description}
                        /> */}

                        <label>Tipo Débito</label>
                        <Field
                          class="form-control"
                          name="tipoDebito"
                          as="select"
                          // onChange={handleChange}
                        >
                          <option value="">Todos</option>
                          <option value="automatico">Automatico</option>
                          <option value="cuenta">Cuenta</option>
                        </Field>
                      </Col>
                      <Col md="2" className="mt-31">
                        <label>&nbsp;</label>
                        <Button onClick={handleSubmit}>
                          <i className="fa fa-search mr-2"></i>Buscar
                        </Button>
                      </Col>
                    </Row>

                    <div className="table-responsive">
                      <DataGridForm
                        dataSource={contenido}
                        // remoteOperations={false}
                        columns={[
                          { caption: "Identificador", dataField: "guid" },
                          {
                            caption: "Fecha",
                            dataField: "fecha",
                          },
                          {
                            caption: "Nro. Socio",
                            dataField: "socialSecurity",
                          },
                          {
                            caption: "Nombre y Apellido",
                            dataField: "fullName",
                          },
                          {
                            caption: "Tipo",
                            dataField: "tipoDebito",
                          },
                          {
                            caption: "Tipo Operación",
                            dataField: "tipoOperacion",
                          },
                          { caption: "Tarjeta", dataField: "tarjeta" },
                        ]}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogsDebito);
