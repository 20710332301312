import React from "react";
import { connect } from "react-redux";
import { Nav, Card, Image } from "react-bootstrap";
import BeNavGroup from "./nav/nav-group";
import useMenu from "../../hooks/useMenu";

function MainSidebar(props) {
  const { user } = props;
  const { menu } = useMenu(user);

  return (
    <React.Fragment>
      {/* ============================================================== */}
      {/* 						Navigation Start 						*/}
      {/* ============================================================== */}
      <div className="main-sidebar-nav default-navigation">
        <div className="nano has-scrollbar">
          <div className="nano-content sidebar-nav ">
            <Card.Body className="border-bottom text-center nav-profile">
              <div className="notify setpos">
                <span className="heartbit" />
                <span className="point" />
              </div>
              {user ? (
                <Image
                  alt=""
                  src={
                    user?.empresa?.id === 1
                      ? "/assets/img/logo-hb.png"
                      : "/assets/img/logo-ps.png"
                  }
                  className="toggle-none hidden-xs"
                />
              ) : (
                <Image
                  alt=""
                  src={"/assets/img/logo-hb.png"}
                  className="toggle-none hidden-xs"
                />
              )}
              <p className="text-muted mv-0 toggle-none">
                Bienvenido <br />
                <b>{user && user.username}</b>
              </p>
              <p className="text-muted mv-0 toggle-none">
                Rol <b>{user && user?.role?.name}</b>
              </p>
            </Card.Body>
            <Nav as="ul" className="metisMenu flex-column" id="menu">
              {menu.map((groupItem, key) => {
                return (
                  <React.Fragment key={key}>
                    <li className="nav-heading">
                      <span>{groupItem.groupname.toUpperCase()}</span>
                    </li>
                    <BeNavGroup menu={groupItem.children} />
                  </React.Fragment>
                );
              })}
            </Nav>
          </div>
        </div>
      </div>
      {/* ============================================================== */}
      {/* 						Navigation End	 						*/}
      {/* ============================================================== */}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    user: state.user.data,
  };
};
export default connect(mapStateToProps, null)(MainSidebar);
