/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import DataGridForm from "../../../../components/common/DataGridForm";
import { loadingAction } from "../../../../redux/actions";
import { catchError, FetchApi } from "../../../../utils/fetch";
import CustomStore from "devextreme/data/custom_store";
import "./laboratorio.scss";
import PieChart, {
  Series,
  Label,
  Connector,
  Export,
} from "devextreme-react/pie-chart";
import MonthPickerInput from "react-month-picker-input";

function Laboratorio({ isLoadingAction, isLoading }) {
  const [contenido, setContenido] = React.useState(null);
  const [contenidoCount, setContenidoCount] = React.useState(0);
  const [contenidoPieChar, setContenidoPieChar] = React.useState(null);

  const [query, setQuery] = React.useState({
    monthYear: new Date().toISOString(),
    description: "",
    idLogType: 1,
    idType: 7,
    pageIndex: 0,
    pageSize: -1,
  });

  React.useEffect(() => {
    isLoadingAction(false);
  }, []);

  const getContenido = async () => {
    isLoadingAction(true);
    try {
      const dataPie =  FetchApi(
        `log/api/v1/logdata/getlogactivitystatisticspie`,
        "post",
        query
      );
      dataPie.then((res)=>{
        setContenidoPieChar(res.data.data)
      })
      // console.log(data);

      const { data } = await FetchApi(
        `log/api/v1/logdata/getlogactivitystatistics`,
        "post",
        query
      );
      setContenido(data?.data);
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error en pacientes", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  const handleChangeDate = (maskedValue, selectedYear, selectedMonth) => {
    const anio = maskedValue.split("/")[1];
    const mes = maskedValue.split("/")[0];
    const monthYear = `${anio}-${mes}-01`;

    setQuery((prev) => ({ ...prev, monthYear }));
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header></Card.Header>
            <Card.Body>
              <PieChart
                id="pie"
                dataSource={contenidoPieChar?.pie}
                palette="Bright"
                title="BAJADA INFORMES DE LABORATORIO"
              >
                <Series argumentField="nombre" valueField="porcentaje">
                  <Label visible={true}>
                    <Connector visible={true} width={1} />
                  </Label>
                </Series>
                <Export enabled={true} />
              </PieChart>
              <div className="table-responsive">
                <div className="container-laboratorio">
                  <div>
                    <label>Mes</label>
                    <MonthPickerInput
                      lang="es"
                      year={new Date().getFullYear()}
                      month={new Date().getMonth()}
                      onChange={handleChangeDate}
                    />
                  </div>
                  <div>
                    <label>&nbsp;</label>
                    <Button onClick={getContenido}>
                      <i className="fa fa-search mr-2"></i>Buscar
                    </Button>
                  </div>
                </div>
                <DataGridForm
                  dataSource={contenido?.statistics}
                  keyExpr="m"
                  exportEnabled={true}
                  columns={[
                    { caption: "Fecha y Hora", dataField: "fecha" },
                    //{ caption: "Paciente", dataField: "paciente" },
                    { caption: "Descripción", dataField: "description" },
                  ]}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Laboratorio);
