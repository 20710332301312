/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { FieldArray, Formik } from "formik";
import React, { useMemo } from "react";
import { Button, Card, Col, Form, Row, Accordion } from "react-bootstrap";
import { useDispatch, useSelector, connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AlertInfo, AlertSuccess } from "../../../../components/alert";
import ModalComponent from "../../../../components/modal";
import { loadingAction } from "../../../../redux/actions";
import { BASE_URL, EstadoContenido } from "../../../../utils/constants";
import { catchError, Fetch } from "../../../../utils/fetch";
import MediaUploader from "../../../../utils/media-uploader";
import { validateModel } from "./validationSchema";
import {
  eliminarImagen,
  isHTML,
  removeSpecialJodit,
} from "../../../../utils/tools";
import JoditEditor from "jodit-react";
import LayoutJodit from "../../../../components/customizer/layout-jodit";
import { Jodit } from "jodit-react";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import ObservacionesComponent from "../../../../components/configuracion/pagina-principal/ObservacionesComponent";
import moment from "moment-timezone";
//import './ver.css';

const EditarAyuda = ({ isLoadingAction, isLoading }) => {
  const { id, codigo } = useParams();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = React.useState({});
  const [configuration, setConfiguration] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);
  const user = useSelector((state) => state.user.data);
  const [showObservaciones, setShowObservaciones] = React.useState(false);
  const [observacionesRepetible, setObservacionesRepetible] = React.useState(
    {}
  );
  const [layoutJodit, setLayoutJodit] = React.useState("PC");
  const editorRef = React.useRef(null);

  const history = useHistory();
  React.useEffect(() => {
    if (id) {
      getHomeSlideById();
    }
  }, [id]);

  const getHomeSlideById = async () => {
    try {
      isLoadingAction(true);

      const ayuda = await Fetch(
        "ayudas/" + id + "?_publicationState=preview",
        "get"
      );
      const ayudaConfiguracion = await Fetch(
        `ayuda-configuracions/?codigo=${codigo}&_publicationState=preview`,
        "get"
      );

      Promise.all([ayuda, ayudaConfiguracion]).then((response) => {
        const help = response[0].data;

        if (help.observacionesRepetible) {
          const sortedData = [...help.observacionesRepetible].sort(
            (a, b) =>
              new Date(b.fechaObservacion) - new Date(a.fechaObservacion)
          );
          setObservacionesRepetible(sortedData);
        }

        const helpSetting = response[1].data[0];

        //help.multimedia = help.multimedia === null ? '' : help.multimedia
        help.requeridoDescripcionCorta = helpSetting?.descripcionCorta;
        help.requeridoMultimedia = helpSetting?.multimedia;
        help.requeridoPreguntaDescripcionCorta =
          helpSetting?.preguntaDescripcionCorta;
        //help.requeridoRespuestaMultimedia = helpSetting.respuestaMultimedia

        help?.preguntas.map((item) => {
          item.requeridoPreguntaDescripcionCorta =
            helpSetting?.preguntaDescripcionCorta;
          // item.respuestas.map((itemR) => {
          //     itemR.requeridoRespuestaMultimedia = helpSetting.respuestaMultimedia
          // })
        });

        setFormValues(help);
        setConfiguration(helpSetting);
        isLoadingAction(false);
      });
    } catch (error) {
      catchError(error);
      isLoadingAction(false);
    }
  };

  const config = useMemo(
    () => ({
      readonly: false,
      height: 500,
      minHeight: 500,
      language: "es",
      disablePlugins: ["table"],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      enableDragAndDropFileToEditor: true,
      // uploader: {
      //     insertImageAsBase64URI: true
      // },
      uploader: {
        url: BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];
          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              // add the text node to the newly created div
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    }),
    []
  );

  function preparePaste(jodit) {
    jodit.e.on(
      "paste",
      (e) => {
        jodit.e.stopPropagation("paste");
        let plainText = Jodit.modules.Helpers.getDataTransfer(e).getData(
          Jodit.constants.TEXT_PLAIN
        );
        // plainText = plainText.replace(/(^|;)\s*font-[^;]+/g, "");
        // let regex = /<(?!(\/\s*)?(div|b|i|em|strong|u|p)[>,\s])([^>])*>/g;
        // plainText = plainText.replace(regex, '');

        //plainText = plainText.normalize('NFKC').replaceAll("\n","<br/>").replaceAll("\r","");

        if (!isHTML(plainText)) {
          plainText = plainText.normalize("NFKC");
          plainText = plainText.replace(/\r\n/g, "\n");
          plainText = removeSpecialJodit(plainText);
        }

        // Dividir el texto en líneas separadas
        const lines = plainText.split("\n");

        // Insertar cada línea como un nuevo nodo
        lines.forEach((line) => {
          if (line.startsWith("https://") || line.startsWith("http://")) {
            jodit.s.insertHTML(`<a href="${line}">${line}</a>`);
          } else {
            jodit.s.insertHTML(`<p>${line}</p>`);
          }
        });

        return false;
      },
      { top: true }
    );
  }

  Jodit.plugins.add("preparePaste", preparePaste);

  const submit = async (values) => {
    isLoadingAction(true);

    //values.published_at = null;
    //values.idPadre = values.id;
    //values.estado = EstadoContenido.EnRevision
    values.idEmpresa = id;

    try {
      values.preguntas.map((itemP) => {
        delete itemP.id;
        itemP.respuestasenriquecidas.map((itemR) => {
          delete itemR.id;
        });
      });

      await Fetch("ayudas/" + values.id, "put", values);

      isLoadingAction(false);

      AlertSuccess("Ayuda actualizada con éxito");

      history.push("/configuracion/ayuda/lista");
    } catch (error) {
      catchError(error);
      isLoadingAction(false);
    }
  };

  const onEnviarComentario = async (values, setFieldValue) => {
    try {
      dispatch(loadingAction(true));

      const model = {
        userId: user.id,
        empresaId: id,
        observacion: values.observacionEnviar,
      };
      const addComment = await Fetch("ayudas/addComment", "post", model);

      const nuevaObservacion = {
        publicador: {
          id: user.id,
          nombre: user.nombre,
          apellido: user.apellido,
        },
        observacion: values.observacionEnviar,
        fechaObservacion: new Date(),
      };
      setObservacionesRepetible([nuevaObservacion, ...observacionesRepetible]);

      dispatch(loadingAction(false));
      AlertSuccess("Observación agregada con éxito.");
      setFieldValue("observacionEnviar", "");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  function getUserInitials(userId) {
    const names = (userId.nombre + " " + userId.apellido).split(" ");
    return names.map((n) => n[0]).join("");
  }

  function getUserName(userId) {
    return userId.nombre + " " + userId.apellido;
  }

  const formatDate = (dateString) => {
    const date = moment(dateString).tz("GMT").toDate();
    const fechaFormateada = format(date, "dd 'de' MMMM, yyyy - ", {
      locale: es,
    });
    // Obtener la zona horaria del usuario
    const userTimezone = moment.tz.guess();
    // Formatear la hora en la zona horaria del usuario
    const time = moment(dateString).tz(userTimezone).format("HH:mm:ss");
    return fechaFormateada + " " + time;
  };

  const initialValues = {
    codigo: "",
    titulo: "",
    descripcionCorta: "",
    multimedia: {},
    tipoMultimedia: "I",
    preguntas: [
      {
        titulo: "",
        descripcionCorta: "",
        respuestasenriquecidas: [
          {
            descripcion: "",
          },
        ],
      },
    ],
    idEmpresa: 0,
    requeridoDescripcionCorta: false,
    requeridoMultimedia: false,
    requeridoPreguntaDescripcionCorta: false,
    requeridoRespuestaMultimedia: false,
    observacionEnviar: "",
    observacionesRepetible: [],
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          ...(formValues || initialValues),
          observacionesRepetible,
        }}
        enableReinitialize
        validationSchema={validateModel}
        onSubmit={(values, { setSubmitting }) => {
          submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          setValues,
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Card>
                  <Card.Header>Editar ayuda</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label>
                            <b>Título</b>
                          </label>
                          <input
                            type="text"
                            name="titulo"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.titulo}
                          />
                          <div className="text-danger">{errors?.titulo}</div>
                        </div>
                      </Col>
                    </Row>

                    {/* {configuration?.descripcionCorta && */}
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label>
                            <b>Descripción corta</b>
                          </label>
                          <textarea
                            name="descripcionCorta"
                            className="form-control"
                            onChange={handleChange}
                            value={values?.descripcionCorta}
                          ></textarea>
                          <div className="text-danger">
                            {errors?.descripcionCorta}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* {configuration?.multimedia && */}
                    <>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>
                              <b>Tipo de multimedia</b>
                            </label>
                            <select
                              name="tipoMultimedia"
                              onChange={handleChange}
                              className="form-control"
                              value={values?.tipoMultimedia}
                            >
                              <option value="I">IMAGEN</option>
                              {/* <option value="V">VIDEO</option> */}
                            </select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>
                              <b>Seleccionar archivo</b>
                            </label>
                            {values?.tipoMultimedia === "I" ||
                            values?.tipoMultimedia === null ? (
                              <>
                                <MediaUploader
                                  value={values?.multimedia ? 100 : 0}
                                  key={values?.multimedia}
                                  setFieldValue={setFieldValue}
                                  field={"multimedia"}
                                  tipoMedia="imagen"
                                  ancho={1200}
                                  alto={600}
                                />
                                <span className="text-muted">
                                  Se recomienda una imagen de 1200px de ancho x
                                  600px de alto. En formato JPG o PNG.
                                </span>
                                <div className="text-danger">
                                  {errors?.multimedia}
                                </div>
                              </>
                            ) : (
                              <>
                                <MediaUploader
                                  value={values?.multimedia ? 100 : 0}
                                  key={values?.multimedia}
                                  setFieldValue={setFieldValue}
                                  field={"multimedia"}
                                  tipoMedia="video"
                                  ancho={1920}
                                  alto={1080}
                                />
                                <span className="text-muted">
                                  Se recomienda un video de 1920px de ancho x
                                  1080px de alto. En formato MP4 ó WEBM.
                                </span>
                                <div className="text-danger">
                                  {errors?.multimedia}
                                </div>
                              </>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      {values?.multimedia &&
                        Object.keys(values?.multimedia)?.length > 0 && (
                          <>
                            <Row>
                              <Col>
                                <Form.Group>
                                  <img
                                    src={values?.multimedia?.url}
                                    alt="vista-previa"
                                    className="img-fluid w-50"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Button
                                  type="button"
                                  style={{ marginBottom: "10px" }}
                                  onClick={() => {
                                    eliminarImagen(values?.multimedia.id).then(
                                      (response) => {
                                        setFieldValue("multimedia", "", false);
                                      }
                                    );
                                  }}
                                  className="btn btn-danger btn-icon mt-2"
                                >
                                  <i className="fa fa-trash-o " />
                                  Eliminar
                                </Button>
                              </Col>
                            </Row>
                          </>
                        )}
                    </>

                    {/* Preguntas */}
                    <Row>
                      <Col>
                        <h2>Preguntas</h2>
                        <FieldArray name="preguntas">
                          {({
                            move,
                            swap,
                            push,
                            remove,
                            insert,
                            unshift,
                            pop,
                            form,
                          }) => {
                            return (
                              <>
                                {values.preguntas &&
                                  values.preguntas.length > 0 &&
                                  values.preguntas.map((data, index) => {
                                    return (
                                      <Row key={index}>
                                        <Col>
                                          <Accordion defaultActiveKey="-1">
                                            <Card className="border mb-2">
                                              <Card.Header className="d-flex justify-content-between">
                                                <Accordion.Toggle
                                                  as={Button}
                                                  variant="link"
                                                  eventKey="0"
                                                >
                                                  {index + 1}
                                                </Accordion.Toggle>
                                                <button
                                                  type="button"
                                                  className="btn btn-default"
                                                  onClick={() => remove(index)}
                                                >
                                                  X
                                                </button>
                                              </Card.Header>
                                              <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                  <Row>
                                                    <Col>
                                                      {/* <div className="form-group">
                                                                                                                <label><b>Título</b></label>
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    name={`preguntas.[${index}].titulo`}
                                                                                                                    className="form-control"
                                                                                                                    onChange={handleChange}
                                                                                                                    onBlur={handleBlur}
                                                                                                                    value={values?.preguntas[index]?.titulo}
                                                                                                                />
                                                                                                                {errors &&
                                                                                                                    errors.preguntas &&
                                                                                                                    errors.preguntas[index] &&
                                                                                                                    errors.preguntas[index].titulo &&
                                                                                                                    (
                                                                                                                        <div className="text-danger">{errors?.preguntas[index]?.titulo}</div>
                                                                                                                    )
                                                                                                                }
                                                                                                            </div> */}
                                                    </Col>
                                                  </Row>

                                                  {/* {configuration?.preguntaDescripcionCorta && */}
                                                  <Row>
                                                    <Col>
                                                      <div className="form-group">
                                                        <label>
                                                          <b>Pregunta</b>
                                                        </label>
                                                        <textarea
                                                          name={`preguntas.[${index}].descripcionCorta`}
                                                          className="form-control"
                                                          onChange={
                                                            handleChange
                                                          }
                                                          value={
                                                            values?.preguntas[
                                                              index
                                                            ]?.descripcionCorta
                                                          }
                                                        ></textarea>
                                                        {errors &&
                                                          errors.preguntas &&
                                                          errors.preguntas[
                                                            index
                                                          ] &&
                                                          errors.preguntas[
                                                            index
                                                          ]
                                                            .descripcionCorta && (
                                                            <div className="text-danger">
                                                              {
                                                                errors
                                                                  ?.preguntas[
                                                                  index
                                                                ]
                                                                  ?.descripcionCorta
                                                              }
                                                            </div>
                                                          )}
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                  {/* } */}

                                                  {/* RESPUESTAS */}
                                                  <Row>
                                                    <Col>
                                                      <h2>Respuestas</h2>
                                                      <FieldArray
                                                        name={
                                                          "preguntas[" +
                                                          index +
                                                          "].respuestasenriquecidas"
                                                        }
                                                      >
                                                        {({
                                                          move,
                                                          swap,
                                                          push,
                                                          remove,
                                                          insert,
                                                          unshift,
                                                          pop,
                                                          form,
                                                        }) => {
                                                          return (
                                                            <>
                                                              {values.preguntas[
                                                                index
                                                              ]
                                                                .respuestasenriquecidas &&
                                                                values
                                                                  .preguntas[
                                                                  index
                                                                ]
                                                                  .respuestasenriquecidas
                                                                  .length > 0 &&
                                                                values.preguntas[
                                                                  index
                                                                ].respuestasenriquecidas.map(
                                                                  (
                                                                    dataR,
                                                                    indexR
                                                                  ) => {
                                                                    return (
                                                                      <Row
                                                                        key={
                                                                          indexR
                                                                        }
                                                                      >
                                                                        <Col>
                                                                          <Accordion defaultActiveKey="-1">
                                                                            <Card className="border mb-2">
                                                                              <Card.Header className="d-flex justify-content-between">
                                                                                <Accordion.Toggle
                                                                                  as={
                                                                                    Button
                                                                                  }
                                                                                  variant="link"
                                                                                  eventKey="0"
                                                                                >
                                                                                  {indexR +
                                                                                    1}
                                                                                </Accordion.Toggle>
                                                                                <button
                                                                                  type="button"
                                                                                  className="btn btn-default"
                                                                                  onClick={() =>
                                                                                    remove(
                                                                                      index
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  X
                                                                                </button>
                                                                              </Card.Header>
                                                                              <Accordion.Collapse eventKey="0">
                                                                                <Card.Body>
                                                                                  <Row>
                                                                                    <Col
                                                                                      className={`${
                                                                                        layoutJodit ==
                                                                                          "MOBILE" &&
                                                                                        "col-6"
                                                                                      }`}
                                                                                    >
                                                                                      <Form.Group>
                                                                                        <label>
                                                                                          Contenido
                                                                                        </label>
                                                                                        <LayoutJodit
                                                                                          setLayoutJodit={
                                                                                            setLayoutJodit
                                                                                          }
                                                                                          layoutJodit={
                                                                                            layoutJodit
                                                                                          }
                                                                                        />
                                                                                        <JoditEditor
                                                                                          config={
                                                                                            config
                                                                                          }
                                                                                          value={
                                                                                            values
                                                                                              ?.preguntas[
                                                                                              index
                                                                                            ]
                                                                                              ?.respuestasenriquecidas[
                                                                                              indexR
                                                                                            ]
                                                                                              ?.descripcion
                                                                                          }
                                                                                          ref={
                                                                                            editorRef
                                                                                          }
                                                                                          tabIndex={
                                                                                            1
                                                                                          } // tabIndex of textarea
                                                                                          //onChange={newContent => handleChangeJoiEditor(newContent)}
                                                                                          onBlur={(
                                                                                            newContent
                                                                                          ) =>
                                                                                            setFieldValue(
                                                                                              `preguntas.[${index}].respuestasenriquecidas[${indexR}].descripcion`,
                                                                                              newContent,
                                                                                              false
                                                                                            )
                                                                                          }
                                                                                        />
                                                                                        {errors &&
                                                                                          errors.preguntas &&
                                                                                          errors
                                                                                            .preguntas[
                                                                                            index
                                                                                          ] &&
                                                                                          errors
                                                                                            .preguntas[
                                                                                            index
                                                                                          ]
                                                                                            .respuestas &&
                                                                                          errors
                                                                                            .preguntas[
                                                                                            index
                                                                                          ]
                                                                                            .respuestas[
                                                                                            indexR
                                                                                          ] &&
                                                                                          errors
                                                                                            .preguntas[
                                                                                            index
                                                                                          ]
                                                                                            .respuestas[
                                                                                            indexR
                                                                                          ]
                                                                                            .descripcion && (
                                                                                            <div className="text-danger">
                                                                                              {
                                                                                                errors
                                                                                                  ?.preguntas[
                                                                                                  index
                                                                                                ]
                                                                                                  ?.respuestas[
                                                                                                  indexR
                                                                                                ]
                                                                                                  ?.descripcion
                                                                                              }
                                                                                            </div>
                                                                                          )}
                                                                                      </Form.Group>
                                                                                    </Col>
                                                                                  </Row>
                                                                                </Card.Body>
                                                                              </Accordion.Collapse>
                                                                            </Card>
                                                                          </Accordion>
                                                                        </Col>
                                                                      </Row>
                                                                    );
                                                                  }
                                                                )}
                                                              <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                onClick={() =>
                                                                  push({
                                                                    id: "",
                                                                    descripcion:
                                                                      "",
                                                                    multimedia:
                                                                      "",
                                                                    tipoMultimedia:
                                                                      "I",
                                                                  })
                                                                }
                                                              >
                                                                Añadir respuesta
                                                              </button>
                                                            </>
                                                          );
                                                        }}
                                                      </FieldArray>
                                                    </Col>
                                                  </Row>
                                                </Card.Body>
                                              </Accordion.Collapse>
                                            </Card>
                                          </Accordion>
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    push({
                                      id: "",
                                      titulo: "Agregar pregunta",
                                      descripcionCorta: "",
                                      respuestas: [],
                                    })
                                  }
                                >
                                  Añadir pregunta
                                </button>
                              </>
                            );
                          }}
                        </FieldArray>
                      </Col>
                    </Row>
                    {/* {JSON.stringify(errors)} */}
                    {/* <br/>
                                        <br/> */}
                    {/* {JSON.stringify(values)} */}

                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={isLoading}
                      className="btn btn-success btn-icon mt-2 mb-4"
                    >
                      <i className="fa fa-floppy-o " />
                      Guardar
                    </Button>

                    <Form.Group>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Mostrar Observaciones"
                        checked={showObservaciones}
                        onChange={() =>
                          setShowObservaciones(!showObservaciones)
                        }
                      />
                    </Form.Group>
                    {showObservaciones && (
                      <ObservacionesComponent
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        onEnviarComentario={onEnviarComentario}
                        getUserInitials={getUserInitials}
                        getUserName={getUserName}
                        formatDate={formatDate}
                      />
                    )}
                    {/* {JSON.stringify(errors)} */}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarAyuda);
