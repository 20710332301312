/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Col, Row, Button, Modal } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { AlertInfo } from "../../../../../components/alert";
import DataGridForm from "../../../../../components/common/DataGridForm";
import { loadingAction } from "../../../../../redux/actions";
import { catchError, FetchApi, fetchLogs } from "../../../../../utils/fetch";
import { useHistory } from "react-router-dom";
import EditarIntegranteModal from "../editar";

import "./lista.scss";
import CustomStore from "devextreme/data/custom_store";
import { filterFormated } from "../../../../../utils/tools";

function ListaPacientes({ isLoadingAction }) {
  const user = useSelector((state) => state.user.data);
  const history = useHistory();

  const [contenido, setContenido] = React.useState(null);
  const [estados, setEstados] = React.useState(null);
  const [memberSelected, setMemberSelected] = React.useState({});
  const [state, setState] = React.useState({
    fecha: "",
    estado: "",
    tipoDocumento: "",
    numeroDocumento: "",
    accion: "",
    observations: "",
    idFamilygroup: 0,
    visibleModal: false,
  });

  const [showEditMember, setShowEditMember] = React.useState(false);
  const [member, setMember] = React.useState({});

  React.useEffect(() => {
    onDatosInciales();
  }, []);

  React.useEffect(() => {
    setContenido([]);
  }, [state.fecha, state.estado, state.tipoDocumento, state.numeroDocumento]);

  const onDatosInciales = async () => {
    try {
      isLoadingAction(true);
      const catalogos = FetchApi(
        `customers/Api/v1/User/GetStatusRequestFamilyGroups`,
        "get",
        ""
      );
      // const solicitudes = FetchApi(
      //   `customers/Api/v1/User/GetAllRequestFamilyGroups`,
      //   "post",
      //   {
      //     fechaSolicitud: "",
      //     estado: "",
      //     tipoDocumento: "",
      //     numeroDocumento: "",
      //   }
      // );

      Promise.all([catalogos]).then((response) => {
        const help = response[0].data;
        //const helpSetting = response[1].data[0];

        setEstados(response[0].data?.data?.Catalogues);
        //setContenido(response[1].data?.data?.Solicitudes);
        isLoadingAction(false);
      });
    } catch (error) {
      catchError(error);
      isLoadingAction(false);
    }
  };

  // const getContenido = async () => {
  //   isLoadingAction(true);

  //   try {
  //     isLoadingAction(true);
  //     const { data } = await FetchApi(
  //       `customers/Api/v1/User/GetAllRequestFamilyGroups`,
  //       "post",
  //       {
  //         fechaSolicitud: state.fecha.split("-").reverse().join("-"),
  //         estado: state.estado,
  //         tipoDocumento: state.tipoDocumento,
  //         numeroDocumento: state.numeroDocumento,
  //       }
  //     );
  //     setContenido(data?.data?.Solicitudes);
  //     isLoadingAction(false);
  //   } catch (error) {
  //     console.error("mostrando error en pacientes", error);
  //     isLoadingAction(false);
  //     catchError(error);
  //   }
  // };

  React.useEffect(() => {
    const nuevoContenido = new CustomStore({
        key: "idCustomer",
        async load(loadOptions) {
            const resultado = await FetchApi(
                `customers/Api/v1/User/GetAllRequestFamilyGroups`,
                "post",
                {
                    page: loadOptions.skip / loadOptions.take + 1 || 1,
                    pageSize: loadOptions.take != undefined ? loadOptions.take : 10,
                    //pageSize: 2,
                    filter: state.filter,
                    random: state.random,                                        
                    //sort: loadOptions.sort != undefined ? loadOptions.sort : [{ "selector": "socialSecurityNumber", "desc": false }],                  
                    fechaSolicitud: state.fecha.split("-").reverse().join("-"),
                    estado: state.estado,
                    tipoDocumento: state.tipoDocumento,
                    numeroDocumento: state.numeroDocumento,                        
                    filterColumns:filterFormated(loadOptions.filter)
                }
            );

            resultado.data=resultado?.data?.data?.Solicitudes ?? []
            resultado.totalCount = resultado?.data?.length > 0 ? resultado?.data[0]?.total || 0 : 0;
            
            return resultado;
        },
    });
    
    setContenido(nuevoContenido);
}, [state.random]);

  const getArchivo = async (idMember, nombreCompleto) => {
    try {
      isLoadingAction(true);
      const { data } = await FetchApi(
        `customers/Api/v1/User/GetZipFileByMemberFamily?idMember=${idMember}`,
        "post",
        ""
      );
      isLoadingAction(false);

      if (data?.responseCode === 200) {
        var a = document.createElement("a");
        a.href = "data:application/zip;base64," + data?.data?.Zip;
        a.download = `PeticionGrupoFamiliar_${nombreCompleto.replaceAll(
          " ",
          ""
        )}.zip`;
        a.click();
      } else {
        AlertInfo(data?.message);
      }
    } catch (error) {
      console.error("mostrando error en pacientes", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const onCargarModal = async (data, idFamilygroup, accion) => {
    setMemberSelected(data);
    setState({
      ...state,
      idFamilygroup,
      accion,
      observations: "",
      visibleModal: true,
    });
  };

  const onEditMember = async (data) => {
    setShowEditMember(true);
    setMember(data);
  };

  const onAprobarReprobar = async () => {
    //accion R === Rechazar
    if (state.accion === "R" && state.observations.trim().length <= 0) {
      AlertInfo("Debe de ingresar la observación");
      return false;
    }

    isLoadingAction(true);

    try {
      isLoadingAction(true);
      if (state.accion === "A") {
        //Aprobar
        const { data } = await FetchApi(
          `customers/Api/v1/FamiliarGroup/AcceptFamilyGroup`,
          "post",
          {
            observations: state.observations,
            familygroupId: state.idFamilygroup,
          }
        );
        setState({
          ...state,
          visibleModal: false,
          fecha: "",
          estado: "",
          tipoDocumento: "",
          numeroDocumento: "",
        });
        setContenido(data?.data?.FamilyGroupRequests);

        fetchLogs({
          idLogType: 2,
          idType: 3,
          description: `El administrador ${user.nombre} (${user.email}) ha cambiado el estado del miembro familiar ${memberSelected.nombreCompleto} a aprobado`,
        });

        AlertInfo(data?.message);
      } else {
        //Rechazar
        const { data } = await FetchApi(
          `customers/Api/v1/FamiliarGroup/RejectFamilyGroup`,
          "post",
          {
            observations: state.observations,
            familygroupId: state.idFamilygroup,
          }
        );
        setState({
          ...state,
          visibleModal: false,
          fecha: "",
          estado: "",
          tipoDocumento: "",
          numeroDocumento: "",
        });
        setContenido(data?.data?.FamilyGroupRequests);
        fetchLogs({
          idLogType: 2,
          idType: 3,
          description: `El administrador ${user.nombre} (${user.email}) ha cambiado el estado del miembro familiar ${memberSelected.nombreCompleto} a reprobado`,
        });
        AlertInfo(data?.message);
      }
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error en pacientes", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  function DescargaArchivo(data) {
    return (
      <>
        <span
          role={"button"}
          onClick={() => getArchivo(data.idMiembro, data.nombreCompleto)}
        >
          <i className="fa fa-download"></i>
        </span>
      </>
    );
  }

  function Accion(data) {
    return (
      <>
        {user?.role?.type == "superadmin" && (
          <span
            role={"button"}
            title="Editar"
            onClick={() => onEditMember(data)}
          >
            <i
              style={{ fontSize: 20, marginRight: 5 }}
              className="fa fa-pencil fa-2x"
            ></i>
          </span>
        )}
        {user?.role?.type == "admin" && (
          <span
            role={"button"}
            title="Editar"
            onClick={() => onEditMember(data)}
          >
            <i
              style={{ fontSize: 20, marginRight: 5 }}
              className="fa fa-eye fa-2x"
            ></i>
          </span>
        )}

        {data.idEstado === "PDA" && (
          <>
            <span
              role={"button"}
              title="Aprobar"
              onClick={() => onCargarModal(data, data.idMiembro, "A")}
            >
              <i
                style={{ fontSize: 22, marginRight: 5 }}
                className="fa fa-check fa-2x"
              ></i>
            </span>{" "}
            <span
              role={"button"}
              title="Reprobar"
              onClick={() => onCargarModal(data, data.idMiembro, "R")}
            >
              <i style={{ fontSize: 22 }} className="fa fa-times fa-2x"></i>
            </span>
          </>
        )}
      </>
    );
  }

  const isBornHBRender = (data) => {
    return data?.isBornHB == "Si" ? "Si" : "No";
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>SOLICITUDES DE ADHESIÓN A GRUPO FAMILIAR</Card.Header>
            <Card.Body>
              <Row className="mb-15">
                <Col md="2">
                  <label>Fecha de solicitud</label>
                  <input
                    placeholder="DD-MM-YYYY"
                    name="fecha"
                    onChange={onChange}
                    value={state.fecha}
                    type="date"
                    class="form-control"
                  />
                </Col>
                <Col md="2">
                  <label>Estado</label>
                  <select
                    class="form-control"
                    name="estado"
                    onChange={onChange}
                    value={state.estado}
                  >
                    <option selected value="">
                      Seleccione uno
                    </option>
                    {estados?.map((item, idx) => (
                      <option value={item.codigo}>{item.descripcion}</option>
                    ))}
                  </select>
                </Col>
                <Col md="2">
                  <label>Tipo doc.</label>
                  <select
                    class="form-control"
                    name="tipoDocumento"
                    onChange={onChange}
                    value={state.tipoDocumento}
                  >
                    <option selected="selected" value="">
                      Seleccione uno
                    </option>
                    <option value="0">DU</option>
                    <option value="2">Pasaporte</option>
                  </select>
                </Col>
                <Col md="2">
                  <label>Nro. documento</label>
                  <input
                    type="number"
                    name="numeroDocumento"
                    onChange={onChange}
                    value={state.numeroDocumento}
                    class="form-control"
                  />
                </Col>
                <Col md="2" className="mt-31">
                  <label>&nbsp;</label>
                  <Button onClick={()=>{
                    setState({ ...state, random: Math.random() });
                  }}>
                    <i className="fa fa-search mr-2"></i>Buscar
                  </Button>
                </Col>
              </Row>

              <div className="table-responsive">
                <DataGridForm
                  dataSource={contenido}
                  remoteOperations={true}
                  pageSize={10}
                  keyExpr="idMiembro"
                  columns={[
                    { caption: "Nombre completo", dataField: "nombreCompleto" },
                    { caption: "Credencial", dataField: "credencial" },
                    { caption: "Fecha de nac.", dataField: "fechaNacimiento" },
                    { caption: "Parentesco", dataField: "parentesco" },
                    {
                      caption: "Doc. adjunta",
                      alignment: "center",
                      render: "DescargaArchivo",
                      cellRender: { DescargaArchivo },
                      width: 50,
                    },
                    {
                      caption: "Nac/Atd HB",
                      render: "isBornHBRender",
                      cellRender: { isBornHBRender },
                    },
                    { caption: "Estado", dataField: "estado" },
                    { caption: "Observaciones", dataField: "observacion" },
                    { caption: "Responsable", dataField: "responsable" },
                    { caption: "IDTEMP", dataField: "idTemp" },
                    { caption: "IDSAP", dataField: "idSap" },
                    {
                      caption: "Acción",
                      alignment: "center",
                      render: "Accion",
                      cellRender: { Accion },
                      width: 100,
                    },
                  ]}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        show={state.visibleModal}
        size="lg"
        onHide={() => setState({ ...state, visibleModal: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {state.accion === "A" ? "Aprobar solicitud" : "Reprobar solicitud"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            name="observations"
            className="form-control"
            onChange={onChange}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onAprobarReprobar}>
            {state.accion === "A" ? "Aprobar" : "Reprobar"}
          </Button>
        </Modal.Footer>
      </Modal>
      <EditarIntegranteModal
        isShow={showEditMember}
        member={member}
        onDatosInciales={onDatosInciales}
        handleClose={() => setShowEditMember(false)}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaPacientes);
