import "devextreme/dist/css/dx.light.css";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";

import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "./redux/store";

// Import as a module in your JS
import "react-bootstrap-typeahead/css/Typeahead.css";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Layout from "./layouts";

// Components
import PrivateRoute from "./routes/PrivateRoute";

// Auth
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import ResetPassword from "./pages/auth/reset-password";

// Dashboard
import Dashboard from "./pages/dashboard";

// Ayuda
import Ayudas from "./pages/configuracion/ayuda/lista";
import EditarAyuda from "./pages/configuracion/ayuda/editar";

//Paginas
import ListaPacientes from "./pages/admin/usuarios/pacientes/lista-pacientes";
import GrupoFamiliar from "./pages/admin/usuarios/grupo-familiar/lista";
import GrupoFamiliarEdit from "./pages/admin/usuarios/grupo-familiar/editar";
import Empresas from "./pages/admin/usuarios/empresas/lista";
import Administradores from "./pages/admin/usuarios/administradores/lista";
import LogsActividad from "./pages/admin/logs/logs-actividad";
import LogsEstudio from "./pages/admin/logs/logs-estudio";
import LogsFacturacion from "./pages/admin/logs/logs-facturacion";
import LogsFeatures from "./pages/admin/logs/logs-features";
import LogsDebito from "./pages/admin/logs/logs-debito";
import LogsReintegros from "./pages/admin/logs/logs-reintegros";
import Facturacion from "./pages/admin/estadisticas/facturacion";
import Laboratorio from "./pages/admin/estadisticas/laboratorio";
import InformesFacturas from "./pages/admin/informes-facturas";
import ModificarUsuario from "./pages/admin/modificar-usuario";
import ModificarEmpresa from "./pages/admin/modificar-empresa";

//Página configuración
import Seteo from "./pages/admin/configuracion/seteo";
import ServidorCorreo from "./pages/admin/configuracion/servidor-correo";
import CorreosPacientes from "./pages/admin/configuracion/correos-pacientes";

//Configuracion - Home Slide
import ListarHomeSlides from "./pages/configuracion/home-slides/lista";
import ListarBorradorHomeSlides from "./pages/configuracion/home-slides/lista-borradores";
import ListarHomeSlidesX from "./pages/configuracion/home-slides/listax";
import ListarPorEliminarHomeSlides from "./pages/configuracion/home-slides/lista-por-eliminar";
import NuevoHomeSlide from "./pages/configuracion/home-slides/nuevo";
import VerHomeSlide from "./pages/configuracion/home-slides/ver";
import VerBorradorHomeSlide from "./pages/configuracion/home-slides/ver-borrador";
import VerHomeSlideX from "./pages/configuracion/home-slides/verx";
import VerEliminarHomeSlide from "./pages/configuracion/home-slides/ver-para-eliminar";

import Inicio from "./pages/admin/inicio";

//Pàgina Principal
import { PaginaPrincipalEnRevisionPage } from "./pages/configuracion/pagina-principal/en-revision";
import { PaginaPrincipalPorAprobarPage } from "./pages/configuracion/pagina-principal/por-aprobar";
import { PaginaPrincipalPublicadoPage } from "./pages/configuracion/pagina-principal/publicado";

import * as serviceWorker from "./serviceWorker";
import NuevoUsuario from "./pages/admin/usuarios-cms/nuevo-usuario";
import ListaUsuariosCMS from "./pages/admin/usuarios-cms/lista";
import createPassword from "./pages/auth/create-password";
import updatePassword from "./pages/auth/update-password";
import logsUsuarioCms from "./pages/admin/logs/logs-usuario-cms";
import FooterEnRevisionPage from "./pages/footer/en-revision";
import FooterPublicadoPage from "./pages/footer/publicado";
import FooterPorAprobarPage from "./pages/footer/por-aprobar";

let persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <BrowserRouter basename={"/"}>
          <Switch>
            <Route
              exact
              path="/auth"
              render={() => <Redirect to="/auth/login" />}
            />
            <Route path={`/auth/login`} component={Login} />
            <Route path={`/auth/register`} component={Register} />
            <Route path={`/auth/forget-password`} component={ResetPassword} />


            <Route path={`/auth/create-password`} component={createPassword} />
            <Route path={`/auth/update-password`} component={updatePassword} />

            <Layout name="backend">
              <Route
                exact
                path="/"
                render={() => <Redirect to="/dashboard" />}
              />
              <PrivateRoute path={`/dashboard`} component={Dashboard} />

              {/* Ayudas */}
              <PrivateRoute
                path={`/configuracion/ayuda/lista`}
                component={Ayudas}
              />
              <PrivateRoute
                path={`/configuracion/ayuda/editar/:id/:codigo`}
                component={EditarAyuda}
              />

              {/* Paginas */}
              <PrivateRoute path={`/inicio`} component={Inicio} />
              <PrivateRoute
                path={`/usuarios/pacientes/lista-pacientes`}
                component={ListaPacientes}
              />
              <PrivateRoute
                path={`/usuarios/grupo-familiar/lista`}
                component={GrupoFamiliar}
              />
              <PrivateRoute
                path={`/usuarios/empresas/lista`}
                component={Empresas}
              />
              <PrivateRoute
                path={`/usuarios/administradores/lista`}
                component={Administradores}
              />

              {/* Usuarios CMS */}
              <PrivateRoute path={`/usuarios/cms/nuevo-usuario`} component={NuevoUsuario} />
              <PrivateRoute path={`/usuarios/cms/editar-usuario/:id`} component={NuevoUsuario} />
              <PrivateRoute path={`/usuarios/cms/lista`} component={ListaUsuariosCMS}/>


              <PrivateRoute path={`/configuracion/seteo`} component={Seteo} />
              <PrivateRoute
                path={`/configuracion/servidor-correo`}
                component={ServidorCorreo}
              />
              <PrivateRoute
                path={`/configuracion/correos-pacientes`}
                component={CorreosPacientes}
              />
              <PrivateRoute
                path={`/configuracion/correos-empresariales`}
                component={CorreosPacientes}
              />
              <PrivateRoute
                path={`/logs/logs-actividad`}
                component={LogsActividad}
              />
              <PrivateRoute
                path={`/logs/logs-estudio`}
                component={LogsEstudio}
              />
              <PrivateRoute
                path={`/logs/logs-facturacion`}
                component={LogsFacturacion}
              />
              <PrivateRoute
                path={`/logs/logs-features`}
                component={LogsFeatures}
              />
              <PrivateRoute path={`/logs/logs-debito`} component={LogsDebito} />
              <PrivateRoute path={`/logs/logs-reintegros`} component={LogsReintegros} />

              <PrivateRoute path={`/logs/logs-usuarios-cms`} component={logsUsuarioCms} />

              <PrivateRoute
                path={`/estadisticas/facturacion`}
                component={Facturacion}
              />
              <PrivateRoute
                path={`/estadisticas/laboratorio`}
                component={Laboratorio}
              />
              <PrivateRoute
                path={`/informes-facturas`}
                component={InformesFacturas}
              />
              <PrivateRoute
                path={`/modificar-usuario/:id`}
                component={ModificarUsuario}
              />
              <PrivateRoute
                path={`/modificar-empresa/:id`}
                component={ModificarEmpresa}
              />

              {/* Configuracion Home Slide */}
              <PrivateRoute
                path={`/configuracion/home-slide/lista`}
                component={ListarHomeSlides}
              />
              <PrivateRoute
                path={`/configuracion/home-slide/lista-borradores`}
                component={ListarBorradorHomeSlides}
              />
              <PrivateRoute
                path={`/configuracion/home-slide/listax`}
                component={ListarHomeSlidesX}
              />
              <PrivateRoute
                path={`/configuracion/home-slide/lista-por-eliminar`}
                component={ListarPorEliminarHomeSlides}
              />
              <PrivateRoute
                path={`/configuracion/home-slide/nuevo`}
                component={NuevoHomeSlide}
              />
              <PrivateRoute
                path={`/configuracion/home-slide/ver/:id`}
                component={VerHomeSlide}
              />
              <PrivateRoute
                path={`/configuracion/home-slide/ver-borrador/:id`}
                component={VerBorradorHomeSlide}
              />
              <PrivateRoute
                path={`/configuracion/home-slide/ver-para-eliminar/:id`}
                component={VerEliminarHomeSlide}
              />
              <PrivateRoute
                path={`/configuracion/home-slide/aprobar/:id`}
                component={VerHomeSlideX}
              />

              {/* Pàgina principal */}
              <PrivateRoute
                path={`/configuracion/pagina-principal/en-revision`}
                component={PaginaPrincipalEnRevisionPage}
              />
              <PrivateRoute
                path={`/configuracion/pagina-principal/por-aprobar`}
                component={PaginaPrincipalPorAprobarPage}
              />
              <PrivateRoute
                path={`/configuracion/pagina-principal/publicado`}
                component={PaginaPrincipalPublicadoPage}
              />

                {/* Configuracion Footer */}
            <PrivateRoute path={`/configuracion/footer/borrador/`} component={FooterEnRevisionPage} />
            <PrivateRoute path={`/configuracion/footer/publicado`} component={FooterPublicadoPage} />
            <PrivateRoute path={`/configuracion/footer/aprobar/`} component={FooterPorAprobarPage} />             

            </Layout>

            {/* <Route path='*' component={ Page404 } /> */}
          </Switch>
        </BrowserRouter>
        <ToastContainer />
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
