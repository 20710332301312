import * as Yup from 'yup';

export const validateModel = (user) =>  Yup.object().shape({
    Member: Yup.object().shape({
        lastName: Yup.string().required('Campo obligatorio'),
        firstName: Yup.string().required('Campo obligatorio'),
        email: Yup.string().required('Campo obligatorio'),
        sex: Yup.string().required('Campo obligatorio'),
        dniType: Yup.string().required('Campo obligatorio'),
        dni: Yup.string().required('Campo obligatorio'),
        cellCompany: Yup.string().required('Campo obligatorio'),
        mobile: Yup.string().required('Campo obligatorio'),
        // phone: Yup.string().required('Campo obligatorio'),
        socialSecurity: Yup.string()
            .when([], {
                is: () => user.role.id === 7,  
                then: Yup.string().required('Campo obligatorio'),
                otherwise: Yup.string().notRequired(),
            }),
        socialSecurityPlan: Yup.string()
            .when([], {
                is: () => user.role.id === 7,  
                then: Yup.string().when('socialSecurity', {
                    is: (value) => value !== 'PARTICULAR',
                    then: Yup.string().required('Campo obligatorio').test(
                        'is-valid-socialSecurityPlan',
                        'Debes seleccionar un plan válido',
                        function (value) {
                            return value !== "";
                        }
                    ),
                    otherwise: Yup.string().notRequired(),
                }),
                otherwise: Yup.string().notRequired(),
            }),
        socialSecurityNumber: Yup.string()
            .when([], {
                is: () => user.role.id === 7,  
                then: Yup.string().when('socialSecurity', {
                    is: (value) => value !== 'PARTICULAR',
                    then: Yup.string().required('Campo obligatorio'),
                    otherwise: Yup.string().notRequired(),
                }),
                otherwise: Yup.string().notRequired(),
            }),
        familyMembers: Yup.number().required('Campo obligatorio'),
        // ivaTax: Yup.string().required('Campo obligatorio'),
        accessLevel: Yup.string().required('Campo obligatorio'),
        sectorLevel: Yup.string().required('Campo obligatorio'),
        // idSap: Yup.string().typeError('idSap no puede estar vacio').required('Campo obligatorio'),
    })
});