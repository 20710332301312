/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Col, Row,Button,Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link,useParams,useHistory  } from 'react-router-dom';
import DataGridForm from '../../../components/common/DataGridForm';
import { loadingAction } from '../../../redux/actions';
import { catchError, Fetch, FetchApi } from '../../../utils/fetch';
import { formatDate } from '../../../utils/formatDate';
import "./modificar-empresa.scss";
import {useFormik} from "formik";
import validateModel from './validate';
import { AlertError, AlertSuccess } from '../../../components/alert';

function ModificarEmpresa({ isLoadingAction, isLoading }) {
    // const [contenido, setContenido] = React.useState(null)
    const {id}=useParams();

    const formik = useFormik({
        initialValues: {   
            firstName:"",
            lastName:"",
            socialSecurity:"",
            email:"",
            phone:"",
            mobile:"",
            accessLevel:"",
            sectorLevel:""
            
        },           
        validationSchema:validateModel,              
        onSubmit: values => {            
            isLoadingAction(true);

            FetchApi(`customers/Api/v1/UpdateEnterprise`, 'put', {
                ...values
             }).then((response)=>{
                AlertSuccess('Página actualizada con éxito');
             }).catch((error)=>{
                AlertError('Los datos no pudieron ser cambiados');
             }).finally((fin)=>{
                isLoadingAction(false)
             })
   
        },
   
      });
    

    React.useEffect(() => {
        getContenido()
    }, [])

    const getContenido = async () => {
        isLoadingAction(true)    
        try {           
            FetchApi(`customers/Api/v1/User/GetMember`, 'post', {
               idCustomer:id
            }).then((response)=>{
                //setContenido(response.data.data.Member);
                formik.setValues(response.data.data.Member);
            });
           
            // setContenido(data)           
            isLoadingAction(false)            
        } catch (error) {
            console.error('mostrando error en pacientes', error)
            isLoadingAction(false)
            catchError(error);
        }
    }


    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header>
                            MODIFICACIÓN DE EMPRESA
                        </Card.Header>
                        <Card.Body>
                        <form onSubmit={formik.handleSubmit} className="formEmpresa">
                            <Row className='mb-3'>
                                <Col md="6" className=''>                                
                                    <label>Apellido </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-custom input-group-text" id="basic-addon1">
                                                <i class="fas fa-user"></i>
                                            </span>
                                        </div>                                        
                                        <input placeholder='' name="lastName" value={formik.values.lastName} type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} className="form-control" />                                                                                
                                    </div> 
                                    {(formik.errors.lastName && formik.touched.lastName) ? <div className="text-danger">{formik.errors.lastName}</div>:<></>}                                        
                                </Col>

                                <Col md="3" className=''>                                
                                    <label>Nombre completo </label>
                                    <div className="input-group">                                        
                                        <input placeholder='' name="firstName" value={formik.values.firstName} type="text" onChange={formik.handleChange} onBlur={formik.handleBlur}  className="form-control" />                                                                            
                                    </div> 
                                    {(formik.errors.firstName && formik.touched.firstName) ? <div className="text-danger">{formik.errors.firstName}</div>:<></>}
                                </Col>                                
                                <Col md="3" className="">                                
                                    <label>Cargo</label>
                                    <div className="input-group">                                        
                                        <input placeholder='' name="socialSecurity" value={formik.values.socialSecurity} type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} className="form-control" />                                                               
                                    </div> 
                                    {(formik.errors.socialSecurity && formik.touched.socialSecurity) ? <div className="text-danger">{formik.errors.socialSecurity}</div>:<></>}         
                                </Col>                                
                            </Row>

                            <Row className='mb-3'>
                                <Col md="6">                                
                                    <label>Email </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-custom input-group-text" id="basic-addon1">
                                                <i class="fas fa-at"></i>
                                            </span>
                                        </div>
                                        <input name="email" value={formik.values.email} type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} className="form-control" />                                                                            
                                    </div> 
                                    {(formik.errors.email && formik.touched.email) ? <div className="text-danger">{formik.errors.email}</div>:<></>}         
                                </Col>

                                <Col md="6">                                
                                    <label>CUIT</label>
                                    <div className="input-group">                                        
                                    <input name="idCUIT" value={formik.values.idCUIT} type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} className="form-control" />                                                                           
                                    </div> 
                                    {(formik.errors.idCUIT && formik.touched.idCUIT) ? <div className="text-danger">{formik.errors.idCUIT}</div>:<></>}      
                                </Col>                                
                            </Row>

                            <Row className='mb-3'> 

                              <Col md="6">                                
                                    <label>Número de télefono</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-custom input-group-text" id="basic-addon1">
                                            <i class="fas fa-phone"></i>
                                            </span>
                                        </div>
                                        <input placeholder=''  name="phone" value={formik.values.phone} type="text" onChange={formik.handleChange} onBlur={formik.handleBlur}  className="form-control" />                                                                                
                                    </div>
                                    {(formik.errors.phone && formik.touched.phone) ? <div className="text-danger">{formik.errors.phone}</div>:<></>}      
                                </Col>                                                                                                

                                <Col md="6">                                
                                    <label>Número de celular</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-custom input-group-text" id="basic-addon1">
                                            <i class="fas fa-mobile"></i>
                                            </span>
                                        </div>
                                        <input placeholder='' name="mobile" value={formik.values.mobile} type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} className="form-control" />                                                                            
                                    </div> 
                                    {(formik.errors.mobile && formik.touched.mobile) ? <div className="text-danger">{formik.errors.mobile}</div>:<></>}         
                                </Col> 

                              

                            </Row>
                          
                            <Row className='mb-3'>
                            <Col md="3">                                
                                    <label>Permiso de acceso</label>
                                    <div className="input-group">
                                        <select class="form-control" name="accessLevel" onChange={formik.handleChange} value={formik.values.accessLevel}>                                            
                                            <option value="0">SIN VALIDAR</option>
                                            <option value="1">VALIDADO</option>
                                            <option value="2">ACCESO TURNOS</option>
                                            <option value="4">ACCESO LABORATORIO</option>
                                            <option value="8">ACCESO FACTURAS</option>
                                            <option value="16">ACCESO EMPRESAS</option>
                                            <option value="999">SUPERADMIN</option>
                                            <option value="1024">OPERADOR</option>
                                            <option value="2048">ADMINISTRADOR</option>
                                        </select>
                                    </div> 
                                </Col>
                                <Col md="3">                                
                                    <label>Área de acceso</label>
                                    <div className="input-group">
                                        <select class="form-control" name="sectorLevel" onChange={formik.handleChange} value={formik.values.sectorLevel}>                                            
                                            <option value="0">NONE</option>
                                            <option value="1">SECTOR_1</option>
                                            <option value="2">SECTOR_2</option>
                                            <option value="4">SECTOR_3</option>
                                            <option value="8">SECTOR_4</option>
                                            <option value="2048">ALL</option>
                                        </select>
                                    </div> 
                                </Col>                               
                               
                            </Row>                            
                            <Row>
                               <Col md="2" className="mt-31">
                                <Button type='submit'><i className="fa fa-save mr-2"></i>Guardar</Button>
                               </Col>
                            </Row>

                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ModificarEmpresa);