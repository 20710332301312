/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./index.scss";
import { Row, Col } from "react-bootstrap";
import { Fetch, catchError } from "../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import { loadingAction } from "../../redux/actions";
import { Link, useHistory } from "react-router-dom";
import { badgesAction } from "../../redux/actions";

function Dashboard() {
  const history = useHistory();
  const [stats, setStats] = React.useState({
    homeSlide: 0,
    paginaPrincipal: 0,
  });
  const dispatch = useDispatch();
  const isLoadingAction = (data) => dispatch(loadingAction(data));
  const idEmpresa = useSelector((state) => state.user.data.empresa.id);
  const idRol = useSelector((state) => state.user.data.role.id);
  const TELEPERFORMANCE = 5;

  const getStats = async () => {
    isLoadingAction(true);
    try {
      const { data: homeSlideCount } = await Fetch(
        `home-slides/count/porEstado?idEmpresa=${idEmpresa}`,
        "get"
      );
      const { data: paginaPrincipalCount } = await Fetch(
        `pagina-principals/count/porEstado?idEmpresa=${idEmpresa}`,
        "get"
      );
      Promise.all([homeSlideCount, paginaPrincipalCount]).then((response) => {
        setStats({
          homeSlide: response[0],
          paginaPrincipal: response[1],
        });
        dispatch(
          badgesAction({
            homeSlide: response[0],
            paginaPrincipal: response[1],
          })
        );
      });

      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error", error);
      isLoadingAction(false);
      if (idRol !== TELEPERFORMANCE) {
        catchError(error);
      } else {
        history.push("/inicio");
      }
    }
  };

  React.useEffect(() => {
    getStats();
  }, []);
  return (
    <React.Fragment>
      <Row>
        <Col xs={12} md={4}>
          <div className="widget bg-light padding-0">
            {idRol !== TELEPERFORMANCE ? (
              <Row className="row-table">
                <div className="col-xs-4 text-center padding-15 bg-teal">
                  {/* <em className="fad fa-dot-circle fa-2x" /> */}
                  BANNERS
                </div>
                <div className="col-xs-6 padding-15">
                  <div className="card-item">
                    <div className="margin-b-0 text-muted">
                      <Link to={`/configuracion/home-slide/lista`}>
                        Aprobados
                      </Link>
                    </div>
                    <div className="margin-b-0">
                      {stats.homeSlide.aprobados}
                    </div>
                  </div>
                  <div className="card-item">
                    <div className="margin-b-0 text-muted">
                      <Link to={`/configuracion/home-slide/listax`}>
                        Por aprobar
                      </Link>
                    </div>
                    <div className="margin-b-0">
                      {stats.homeSlide.poraprobar}
                    </div>
                  </div>
                  <div className="card-item">
                    <div className="margin-b-0 text-muted">
                      <Link to={`/configuracion/home-slide/lista-borradores`}>
                        Por revisar
                      </Link>
                    </div>
                    <div className="margin-b-0">
                      {stats.homeSlide.porrevisar}
                    </div>
                  </div>
                  <div className="card-item">
                    <div className="margin-b-0 text-muted">
                      <Link to={`/configuracion/home-slide/lista-por-eliminar`}>
                        Por eliminar
                      </Link>
                    </div>
                    <div className="margin-b-0">
                      {stats.homeSlide.poreliminar}
                    </div>
                  </div>
                </div>
              </Row>
            ) : (
              <></>
            )}
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="widget bg-light padding-0">
            {idRol !== TELEPERFORMANCE ? (
              <Row className="row-table">
                <div className="col-xs-4 text-center padding-15 bg-teal">
                  {/* <em className="fad fa-dot-circle fa-2x" /> */}
                  PÁGINA PRINCIPAL
                </div>
                <div className="col-xs-6 padding-15">
                  <div className="card-item">
                    <div className="margin-b-0 text-muted">
                      <Link to={`/configuracion/pagina-principal/publicado`}>
                        Aprobados
                      </Link>
                    </div>
                    <div className="margin-b-0">
                      {stats.paginaPrincipal.aprobados}
                    </div>
                  </div>
                  <div className="card-item">
                    <div className="margin-b-0 text-muted">
                      <Link to={`/configuracion/pagina-principal/por-aprobar`}>
                        Por aprobar
                      </Link>
                    </div>
                    <div className="margin-b-0">
                      {stats.paginaPrincipal.poraprobar}
                    </div>
                  </div>
                  <div className="card-item">
                    <div className="margin-b-0 text-muted">
                      <Link to={`/configuracion/pagina-principal/en-revision`}>
                        Por revisar
                      </Link>
                    </div>
                    <div className="margin-b-0">
                      {stats.paginaPrincipal.porrevisar}
                    </div>
                  </div>
                  <div className="card-item">
                    <div className="margin-b-0 text-muted">&nbsp;</div>
                    <div className="margin-b-0"></div>
                  </div>
                </div>
              </Row>
            ) : (
              <></>
            )}
          </div>
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
    </React.Fragment>
  );
}

export default Dashboard;
