import * as Yup from "yup";

export const validateModelDni = Yup.object().shape({
  numeroDocumento: Yup.string()
    .required("El número de documento es obligatorio")
    .matches(/^[0-9]+$/, "El número de documento debe ser numérico")
    .min(5, "El número de documento debe tener al menos 5 caracteres")
    .max(8, "El número de documento no puede tener más de 8 caracteres"),
});

export const validateModelPasport = Yup.object().shape({
  numeroDocumento: Yup.string()
    .required("El número de documento es obligatorio")
    .min(5, "El número de documento debe tener al menos 5 caracteres")
    .max(20, "El número de documento no puede tener más de 20 caracteres")
    .matches(
      /^[a-zA-Z0-9]*$/,
      "El número de documento solo puede contener letras y números"
    ),
});
